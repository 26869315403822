import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Grid, Button, Paper, CircularProgress, Alert, Chip
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import NavBar from './components/NavBar';
import { useNavigate } from 'react-router-dom';
import theme from './theme';

function IntegrationsPage() {
  const [integrations, setIntegrations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchIntegrations = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch('/api/get-integrations', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setIntegrations(Array.isArray(data.integrations) ? data.integrations : []);
        } else {
          setError('Failed to load integrations.');
        }
      } catch (err) {
        setError('An error occurred while fetching integrations.');
      } finally {
        setLoading(false);
      }
    };

    fetchIntegrations();
  }, []);

  const handleSetupClick = (integrationName) => {
    navigate(`/setup-integration/${encodeURIComponent(integrationName)}`);
  };

  const handleEditClick = (integrationName) => {
    navigate(`/edit-integration/${encodeURIComponent(integrationName)}`);
  };

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Integrations
        </Typography>
        <Button
          variant="outlined"
          onClick={() => navigate('/alerts')}
          sx={{ marginBottom: 3 }}
        >
          View Alerts
        </Button>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : integrations.length > 0 ? (
          <Grid container spacing={3}>
            {integrations.map((integration) => (
              <Grid item xs={12} md={6} key={integration.name}>
                <Paper sx={{ padding: 2, position: 'relative' }}>
                  <Chip
                    label={integration.active ? 'Active' : 'Inactive'}
                    color={integration.active ? 'success' : 'default'}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                  />
                  <Typography variant="h6">{integration.name}</Typography>
                  <Typography>{integration.description}</Typography>
                  <Button
                    variant="contained"
                    sx={{ marginTop: 1 }}
                    onClick={() =>
                      integration.active
                        ? handleEditClick(integration.name)
                        : handleSetupClick(integration.name)
                    }
                  >
                    {integration.active ? 'Edit Settings' : 'Set Up'}
                  </Button>
                </Paper>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography>No integrations available.</Typography>
        )}
      </Box>
    </ThemeProvider>
  );
}

export default IntegrationsPage;

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useNavigate } from 'react-router-dom'; // Import the hook for navigation

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  padding: '8px 12px',  // Padding can be adjusted based on design needs
}));

export default function AppAppBar() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();  // Initialize the navigate function

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar
      position="fixed"  // Ensures it sticks to the top
      sx={{
        //backgroundColor: 'background.paper',  // Solid background color (no transparency)
        backgroundColor: 'black',
        boxShadow: 1,  // Box shadow to give it some depth
        zIndex: (theme) => theme.zIndex.drawer + 1,  // Ensure it stays on top
        top: 0,  // Hugs the top of the page
      }}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
            <img src="/CIAlogo.png" alt="Logo" style={{ height: 40, width: 'auto', paddingRight: '10px' }} />
            Customer Conversation Intelligence
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              {/* 
              <Button variant="text" color="info" size="small">Features</Button>
              <Button variant="text" color="info" size="small">Testimonials</Button>
              <Button variant="text" color="info" size="small">Highlights</Button>
              <Button variant="text" color="info" size="small">Pricing</Button>
              <Button variant="text" color="info" size="small" sx={{ minWidth: 0 }}>FAQ</Button>
              <Button variant="text" color="info" size="small" sx={{ minWidth: 0 }}>Blog</Button>
              */}
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 1,
              alignItems: 'center',
            }}
          >
            {/* Use navigate function for sign in and sign up */}
            <Button color="primary" variant="contained" size="small" onClick={() => navigate('/login')}>
              Log in
            </Button>
            <Button color="primary" variant="contained" size="small" onClick={() => navigate('/signup')}>
              Sign up
            </Button>
          </Box>
          <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                {/*
                <MenuItem>Features</MenuItem>
                <MenuItem>Testimonials</MenuItem>
                <MenuItem>Highlights</MenuItem>
                <MenuItem>Pricing</MenuItem>
                <MenuItem>FAQ</MenuItem>
                <MenuItem>Blog</MenuItem>
                */}
                <MenuItem>
                  <Button color="primary" variant="contained" fullWidth onClick={() => navigate('/signup')}>
                    Sign up
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button color="primary" variant="outlined" fullWidth onClick={() => navigate('/login')}>
                    Log in
                  </Button>
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}

import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import NavBar from '../components/NavBar';
import theme from '../theme';
import { useParams, useNavigate } from 'react-router-dom';

const ProjectDetails = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    const fetchProject = async () => {
      const token = localStorage.getItem('access_token');
      try {
        const response = await fetch(`/api/get-project/${projectId}`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch project');
        }
        const data = await response.json();
        setProject(data.project);
      } catch (error) {
        console.error('Error fetching project details:', error);
      }
    };

    fetchProject();
  }, [projectId]);

  const handleDeleteProject = async () => {
    const token = localStorage.getItem('access_token');
    try {
      const response = await fetch(`/api/delete-project/${projectId}`, {
        method: 'DELETE',
        headers: { 'Authorization': `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error('Failed to delete project');
      }

      alert('Project deleted successfully');
      navigate('/manage-projects');
    } catch (error) {
      console.error('Error deleting project:', error);
      alert('An error occurred while deleting the project');
    }
  };

  if (!project) return <Typography>Loading...</Typography>;

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          {project.project_name}
        </Typography>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="body1"><strong>Target Audience:</strong> {project.target_audience}</Typography>
          <Typography variant="body1"><strong>Learning Goal:</strong> {project.learning_goal}</Typography>
          <Typography variant="body1"><strong>Interview Length:</strong> {project.interview_length}</Typography>
          <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
            <strong>Discussion Guide:</strong> {project.discussion_guide}
          </Typography>
          <Typography variant="body1"><strong>Created By:</strong> {project.created_by}</Typography>
          <Typography variant="body1"><strong>Created Date:</strong> {project.created_date}</Typography>
          <Typography variant="body1"><strong>Status:</strong> {project.status}</Typography>
        </Paper>
        <Button
          variant="contained"
          color="error"
          sx={{ marginTop: 2 }}
          onClick={() => setDeleteDialogOpen(true)}
        >
          Delete Project
        </Button>

        {/* Confirmation Dialog */}
        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this project? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleDeleteProject} color="error">Delete</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default ProjectDetails;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import * as d3 from 'd3';
import NavBar from './components/NavBar';
import FilterBar from './components/FilterBar';
import theme from './theme';
import { DataAndFilterContext } from './context/DataAndFilterContext';

function OpportunitySolutionTreePage() {
  const { loading } = useContext(DataAndFilterContext);
  const [jobMapData, setJobMapData] = useState(null);
  const svgRef = useRef(null);

  useEffect(() => {
    const fetchJobMapData = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`/api/get_job_map`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          console.error(`Failed to load job map data. Status: ${response.status}`);
          throw new Error('Failed to load job map data');
        }

        const data = await response.json();
        setJobMapData(data);
      } catch (error) {
        console.error("Error loading job map data:", error);
      }
    };

    fetchJobMapData();
  }, []);

  useEffect(() => {
    if (!jobMapData || loading) return;

    // Transform data into a hierarchical structure with jobs as roots
    const transformedData = {
      children: jobMapData.map(job => ({
        name: job.job_to_be_done,
        children: Object.entries(job.stages).map(([stage, tasks]) => ({
          name: stage,
          order: job.stages[stage].order, // Adding order info to ensure stage order from JSON
          children: tasks.map(task => ({ name: task }))
        }))
      }))
    };

    const width = 1000;
    const height = 800;

    const svg = d3.select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .style("font", "14px sans-serif");

    svg.selectAll("*").remove();  // Clear previous render

    const g = svg.append("g").attr("transform", `translate(${width / 2}, ${height / 4})`);

    // Apply zoom behavior
    svg.call(d3.zoom()
      .scaleExtent([0.5, 2])
      .on("zoom", (event) => g.attr("transform", event.transform)));

    // Sort stages by order as in JSON data
    transformedData.children.forEach(job => {
      job.children = job.children.sort((a, b) => a.order - b.order);
    });

    // Set up a tree layout
    const treeLayout = d3.tree().nodeSize([220, 150]);
    const root = d3.hierarchy({ children: transformedData.children });
    treeLayout(root);

    // Draw links
    g.append("g")
      .selectAll("path")
      .data(root.links())
      .join("path")
      .attr("fill", "none")
      .attr("stroke", "#ccc")
      .attr("stroke-width", 2)
      .attr("d", d3.linkHorizontal()
        .x(d => d.x)
        .y(d => d.y));

    // Draw nodes
    const nodes = g.selectAll("g.node")
      .data(root.descendants())
      .join("g")
      .attr("class", "node")
      .attr("transform", d => `translate(${d.x},${d.y})`);

    // Draw circles for each node
    nodes.append("circle")
      .attr("r", d => (d.depth === 1 ? 12 : 8))  // Slightly larger for stages
      .attr("fill", d => d.children ? "#6baed6" : "#fd8d3c");

    // Draw text labels with larger font size for stages
    nodes.append("text")
      .attr("dy", d => d.depth === 1 ? "-1.5em" : "0.35em")  // Place stage text above
      .attr("x", d => d.children ? -20 : 20)
      .attr("text-anchor", d => d.children ? "middle" : "start")
      .text(d => d.data.name)
      .style("font-size", d => {
        if (d.depth === 1) return "18px";  // Larger font for stages
        return "14px";                    // Standard for tasks
      })
      .style("fill", "#333");

    // Draw task nodes with rectangular container around text
    nodes.filter(d => !d.children)  // Only for bottom-level nodes (tasks)
      .append("foreignObject")
      .attr("width", 220)           // Fixed width for text wrapping
      .attr("height", "auto")
      .attr("x", -110)              // Center the container
      .attr("y", -10)
      .append("xhtml:div")
      .style("background-color", "#ffd6a5")
      .style("border-radius", "5px")
      .style("padding", "8px")
      .style("text-align", "center")
      .style("font-size", "12px")
      .style("overflow-wrap", "break-word") // Wrap text within the box
      .text(d => d.data.name);

  }, [jobMapData, loading]);

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Box sx={{ padding: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h4" gutterBottom>
          Job Map
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.location.reload()}
        >
          Refresh Job Map
        </Button>
      </Box>
      <FilterBar />
      <Typography variant="h5" gutterBottom>
        Every Job To Be Done can be mapped using 8 distinct stages. Each stage has at least one task customers do during that stage.
      </Typography>
      <Box sx={{ height: '70vh', border: '1px solid #ddd', marginTop: 2, position: 'relative', overflow: 'auto' }}>
        {loading ? (
          <Typography>Loading...</Typography>
        ) : (
          <svg ref={svgRef} style={{ width: '100%', height: '100%' }} />
        )}
      </Box>
    </ThemeProvider>
  );
}

export default OpportunitySolutionTreePage;

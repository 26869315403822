import React, { useState } from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CustomerModal from '../CustomerModal';

const ChosenSolutionsBubbleCharts = ({ filteredData }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  // Group data by solution_choice_category
  const groupedData = filteredData.reduce((acc, item) => {
    const category = item.solution_choice_category;
    if (!acc[category]) acc[category] = { satisfaction: [], positive: [], drawback: [] };

    // Add satisfaction level data per file
    acc[category].satisfaction.push({
      level: item.satisfaction_level,
      quote: item.satisfaction_level_quote,
      justification: item.satisfaction_level_justification,
      customer_name: item.customer_name,
      index: 1, // static Y position
      count: 1,
    });

    // Add positive importance data per object in positives_of_solution_choice
    item.positives_of_solution_choice.forEach((pos) => {
      acc[category].positive.push({
        level: pos.positive_importance,
        quote: pos.positive_importance_quote,
        justification: pos.positive_importance_justification,
        customer_name: item.customer_name,
        index: 1,
        count: 1,
      });
    });

    // Add drawback importance data per object in drawbacks_of_solution_choice
    item.drawbacks_of_solution_choice.forEach((draw) => {
      acc[category].drawback.push({
        level: draw.drawback_importance,
        quote: draw.drawback_importance_quote,
        justification: draw.drawback_importance_justification,
        customer_name: item.customer_name,
        index: 1,
        count: 1,
      });
    });

    return acc;
  }, {});

  // Helper function to adjust Z-axis domain for bubble size based on count
  const calculateZDomain = (data) => {
    const maxCount = Math.max(...data.map((entry) => entry.count));
    return [10, maxCount * 20]; // Ensure larger bubbles appear larger
  };

  // Aggregate data to adjust bubble sizes for overlapping points
  const aggregateData = (data) => {
    const aggregated = {};
    data.forEach((point) => {
      const key = point.level;
      if (!aggregated[key]) {
        aggregated[key] = {
          level: point.level,
          index: point.index,
          count: 0,
          quotes: [],
        };
      }
      aggregated[key].count += 1;
      aggregated[key].quotes.push({
        quote: point.quote,
        score: point.level,
        justification: point.justification,
        customer_name: point.customer_name,
      });
    });
    return Object.values(aggregated);
  };

  // Modal open and close handlers
  const handleBubbleClick = (quotes) => {
    setModalContent(quotes);
    setModalOpen(true);
  };
  const handleClose = () => setModalOpen(false);

  // Customer modal handlers
  const handleCustomerClick = (customerName) => {
    const customer = filteredData.find((item) => item.customer_name === customerName);
    if (customer) {
      setSelectedCustomer(customer); // Pass the full customer object
      setCustomerModalOpen(true);
    }
  };
  const handleCustomerModalClose = () => setCustomerModalOpen(false);

  // Render ScatterChart for each data set (satisfaction, positive, drawback)
  const renderScatterChart = (data, label) => {
    const zDomain = calculateZDomain(data);

    return (
      <ResponsiveContainer width="70%" height={60}>
        <ScatterChart
          width={200} // Adjusted width for compact display
          height={60}  // Compact height
          margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        >
          <XAxis
            type="number"
            dataKey="level"
            domain={[1, 5]}
            ticks={[1, 2, 3, 4, 5]}
            label={{ value: label, position: 'insideBottom', offset: -5 }}
          />
          <YAxis
            type="number"
            dataKey="index"
            height={10}
            width={60}
            tick={false}
            axisLine={false}
          />
          <ZAxis
            type="number"
            dataKey="count"
            domain={zDomain}
            range={[50, 1000]} // Ensure larger minimum bubble size
          />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} content={({ active, payload }) => {
            if (active && payload && payload.length) {
              return <div>{`Count: ${payload[0].payload.count}`}</div>;
            }
            return null;
          }} />
          <Scatter
            data={data}
            fill="#8884d8"
            onClick={(point) => handleBubbleClick(point.quotes)}
            style={{ cursor: 'pointer' }} // Makes cursor look clickable
          />
        </ScatterChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div>
      {Object.keys(groupedData).map((category) => (
        <div key={category} style={{ marginBottom: '10px' }}>
          <Typography variant="h6">{category}</Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ width: '20%', textAlign: 'right', marginRight: '10px' }}>
              Satisfaction Level
            </Typography>
            {renderScatterChart(aggregateData(groupedData[category].satisfaction), 'Satisfaction Level')}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ width: '20%', textAlign: 'right', marginRight: '10px' }}>
              Positive Importance
            </Typography>
            {renderScatterChart(aggregateData(groupedData[category].positive), 'Positive Importance')}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ width: '20%', textAlign: 'right', marginRight: '10px' }}>
              Drawback Importance
            </Typography>
            {renderScatterChart(aggregateData(groupedData[category].drawback), 'Drawback Importance')}
          </div>
        </div>
      ))}

      {/* Modal for showing quotes, scores, and customer names */}
      <Modal open={modalOpen} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            maxHeight: '80vh',
            overflowY: 'auto', // Allows scrolling for long content
          }}
        >
          <Typography variant="h6" component="h2">
            Details
          </Typography>
          {modalContent.map((entry, index) => (
            <div key={index}>
              <p><strong>Quote:</strong> {entry.quote}</p>
              <p><strong>Justification:</strong> {entry.justification}</p>
              <p>
                <strong>Customer Name:</strong>{' '}
                <span
                  style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => handleCustomerClick(entry.customer_name)}
                >
                  {entry.customer_name}
                </span>
              </p>
              {index < modalContent.length - 1 && <hr />} {/* Line between entries */}
            </div>
          ))}
        </Box>
      </Modal>

      {/* Customer Modal */}
      <CustomerModal
        open={customerModalOpen}
        onClose={handleCustomerModalClose}
        customer={selectedCustomer}
      />
    </div>
  );
};

export default ChosenSolutionsBubbleCharts;

import React, { useContext, useState, useMemo } from 'react';
import {
  Box, Typography, Grid, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel
} from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Legend, LineChart, Line } from 'recharts';
import { format, parse, eachMonthOfInterval, subMonths } from 'date-fns';
import { DataAndFilterContext } from '../../context/DataAndFilterContext';
import { DataGrid } from '@mui/x-data-grid';
import CustomerModal from '../CustomerModal';
import PositivesHeatmap from './PositivesHeatmap.js';
import DrawbacksHeatmap from './DrawbacksHeatmap.js';
import ChosenSolutionsBubbleCharts from './ChosenSolutionsBubbleCharts.js';

const COLORS = ['#00C9A7', '#FF6B6B', '#1E90FF', '#FFD700', '#9B59B6'];

// Helper function to wrap text into lines
function wrapText(text, maxWidth) {
  const words = text.split(' ');
  const lines = [];
  let currentLine = words[0];

  for (let i = 1; i < words.length; i++) {
    if (currentLine.length + words[i].length + 1 <= maxWidth) {
      currentLine += ` ${words[i]}`;
    } else {
      lines.push(currentLine);
      currentLine = words[i];
    }
  }
  lines.push(currentLine);
  return lines;
}

// Helper function to ensure all values appear under each bar on the bar chart
function CustomXAxisTick({ x, y, payload }) {
  const { value } = payload;
  const maxWidth = 10; // Set max width in terms of characters for each line
  const lines = wrapText(value, maxWidth);

  return (
    <text
      x={x}
      y={y}
      textAnchor="middle"
      style={{ fontSize: '12px', transform: 'translateY(10px)' }} // CSS transform to adjust position
    >
      {lines.map((line, index) => (
        <tspan x={x} dy={index === 0 ? 0 : 14} key={index}> {/* Line spacing */}
          {line}
        </tspan>
      ))}
    </text>
  );
}


function CurrentSolutions() {
  const { filteredData, filters } = useContext(DataAndFilterContext); // Access filters from context
  const [selectedCategory, setSelectedCategory] = useState('');
  const [page, setPage] = useState(1);
  const [sortModel, setSortModel] = useState([{ field: 'conversation_date', sort: 'desc' }]);
  const [visibleCategories, setVisibleCategories] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);  // Modal state
  const [selectedCustomer, setSelectedCustomer] = useState(null);  // Selected customer state

  const ITEMS_PER_PAGE = 10;

  // Compute data for pie chart and category counts
  const solutionData = useMemo(() => {
    const categoryCount = {};
    filteredData.forEach((item) => {
      const category = item.solution_choice_category;
      if (category) {
        categoryCount[category] = (categoryCount[category] || 0) + 1;
      }
    });

    const totalFiles = filteredData.length;
    const pieChartData = Object.keys(categoryCount).map((category) => ({
      name: category,
      value: categoryCount[category],
      percentage: ((categoryCount[category] / totalFiles) * 100).toFixed(2),
    }));

    return pieChartData.sort((a, b) => b.value - a.value);
  }, [filteredData]);

  // Compute data for satisfaction bar chart
  const satisfactionData = useMemo(() => {
    const categorySatisfaction = {};
    const categoryCount = {};

    filteredData.forEach((item) => {
      const category = item.solution_choice_category;
      if (category && item.satisfaction_level !== null) {
        categorySatisfaction[category] = (categorySatisfaction[category] || 0) + item.satisfaction_level;
        categoryCount[category] = (categoryCount[category] || 0) + 1;
      }
    });

    return Object.keys(categorySatisfaction).map((category) => ({
      name: category,
      averageSatisfaction: (categorySatisfaction[category] / categoryCount[category]).toFixed(2),
    }));
  }, [filteredData]);

  // Generate months range based on date filters
  const monthsInRange = useMemo(() => {
    const defaultEndDate = new Date();
    let startDate;
    let endDate = defaultEndDate;
  
    if (filters.dateRange === '6 months') {
      startDate = subMonths(endDate, 6);
    } else if (filters.dateRange === '3 months') {
      startDate = subMonths(endDate, 3);
    } else if (filters.dateRange === 'Custom' && filters.customDateRange.from && filters.customDateRange.to) {
      startDate = new Date(filters.customDateRange.from);
      endDate = new Date(filters.customDateRange.to);
    } else {
      // For "All Time", find the earliest conversation date in the data
      const earliestDate = filteredData.reduce((earliest, item) => {
        const conversationDate = new Date(item.conversation_date);
        return conversationDate < earliest ? conversationDate : earliest;
      }, new Date());
      startDate = earliestDate;
    }
  
    return eachMonthOfInterval({ start: startDate, end: endDate }).map((month) =>
      format(month, "MMM ''yy")
    );
  }, [filters, filteredData]);    

  // Compute data for line chart showing monthly changes in average satisfaction level
  const lineChartData = useMemo(() => {
    const monthlySatisfaction = {};
    
    // Populate monthly data based on the filteredData
    filteredData.forEach((item) => {
      const category = item.solution_choice_category;
      if (category && item.satisfaction_level !== null) {
        const conversationMonth = format(parse(item.conversation_date, 'MM-dd-yyyy', new Date()), "MMM ''yy");
        
  
        if (!monthlySatisfaction[conversationMonth]) {
          monthlySatisfaction[conversationMonth] = {};
        }
  
        if (!monthlySatisfaction[conversationMonth][category]) {
          monthlySatisfaction[conversationMonth][category] = { totalSatisfaction: 0, count: 0 };
        }
  
        monthlySatisfaction[conversationMonth][category].totalSatisfaction += item.satisfaction_level;
        monthlySatisfaction[conversationMonth][category].count += 1;
      }
    });
  
    // Generate data for each month in the range
    return monthsInRange.map((month) => {
      const monthData = { month };
      solutionData.forEach((category) => {
        // Check if data is available for the category in the current month
        const categoryData = monthlySatisfaction[month]?.[category.name];
        if (categoryData) {
          // Calculate the average satisfaction level if data is present
          monthData[category.name] = (categoryData.totalSatisfaction / categoryData.count).toFixed(2);
        } else {
          // Set the value to 0 if there is no data for this category in the current month
          monthData[category.name] = 1;
        }
      });
      return monthData;
    });
  }, [filteredData, monthsInRange, solutionData]);  

  // Handle toggling visibility of solution categories
  const handleCategoryToggle = (category) => {
    if (visibleCategories.includes(category)) {
      setVisibleCategories(visibleCategories.filter((c) => c !== category));
    } else {
      setVisibleCategories([...visibleCategories, category]);
    }
  };

  // Default visibility: show top 2 categories by count
  useMemo(() => {
    const topTwoCategories = solutionData.slice(0, 2).map((d) => d.name);
    setVisibleCategories(topTwoCategories);
  }, [solutionData]);

  // Handle row click to open the modal
  const handleRowClick = (customer) => {
    setSelectedCustomer(customer);
    setModalOpen(true);  // Open the modal
  };
  
  const handleModalClose = () => {
    setModalOpen(false); // Close the modal
  };

  // Data for the Satisfaction Data table
  const filteredTableData = filteredData
    .filter((item) => (selectedCategory ? item.solution_choice_category === selectedCategory : true))
    .sort((a, b) => {
      if (sortModel[0].field === 'conversation_date') {
        return sortModel[0].sort === 'asc'
          ? new Date(a.conversation_date) - new Date(b.conversation_date)
          : new Date(b.conversation_date) - new Date(a.conversation_date);
      }
      if (sortModel[0].field === 'satisfaction_level') {
        return sortModel[0].sort === 'asc'
          ? a.satisfaction_level - b.satisfaction_level
          : b.satisfaction_level - a.satisfaction_level;
      }
      return 0;
    })
    .slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE);

  const totalTablePages = Math.ceil(
    filteredData.filter((item) => (selectedCategory ? item.solution_choice_category === selectedCategory : true)).length / ITEMS_PER_PAGE
  );


  return (
    <Box>
      {/* First Row: Pie Chart and Satisfaction Bar Chart */}
      <Grid container spacing={4} sx={{ mb: 4 }}>
        {/* Pie Chart */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Chosen Solutions</Typography>
          <Typography variant="body2" gutterBottom>
            These are the solutions that customers tell us they are using.
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={solutionData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                label={({ name }) => name}
              >
                {solutionData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip
                formatter={(value, name, entry) => [`${value} (${entry.payload.percentage}%)`, 'Files']}
              />
            </PieChart>
          </ResponsiveContainer>
        </Grid>

        {/* Satisfaction Bar Chart */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Satisfaction with Chosen Solutions</Typography>
          <ResponsiveContainer width="100%" height={300}>
          <BarChart 
            data={satisfactionData} 
            margin={{ top: 20, right: 20, left: 30, bottom: 20 }} // Increase bottom margin for wrapping space
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="name" 
              tick={<CustomXAxisTick />} // Use custom tick component for wrapping
              interval={0} // Show all labels
              dy={10} // Set dy directly on XAxis to push labels down
            />
            <YAxis 
              domain={[1, 5]} 
              ticks={[1, 2, 3, 4, 5]} 
              tickFormatter={(tick) => {
                if (tick === 1) return '1 Very Unsatisfied';
                if (tick === 5) return '5 Very Satisfied';
                return tick;
              }} 
            />
            <Tooltip />
            <Bar dataKey="averageSatisfaction" fill="#82ca9d" />
          </BarChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>

      {/* Line Chart with Checkboxes for Visibility */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6">Monthly Change in Satisfaction Level</Typography>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart 
            data={lineChartData}
            margin={{ top: 20, right: 20, left: 30, bottom: 20 }} 
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" dy={15} />
            <YAxis domain={[1, 5]} ticks={[1, 2, 3, 4, 5]} tickFormatter={(tick) => {
              if (tick === 1) return '1 Very Unsatisfied';
              if (tick === 5) return '5 Very Satisfied';
              return tick;
            }} />
            <Tooltip />
            {solutionData.map((category, index) => (
              visibleCategories.includes(category.name) && (
                <Line
                  key={category.name}
                  type="monotone" // Change the line type to "monotone" for a smoother appearance
                  dataKey={category.name}
                  stroke={COLORS[index % COLORS.length]}
                  activeDot={{ r: 8 }} // Set a larger dot radius for better visibility
                  dot={{ r: 4 }} // Optional: Configure dots to always show for visibility
                />
              )
            ))}
          </LineChart>
        </ResponsiveContainer>

        {/* Checkboxes for toggling lines */}
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            maxWidth: '100%',
            margin: 'auto',
            mt: 2,
            justifyContent: 'flex-start',
          }}
        >
          {solutionData.map((category, index) => (
            <FormControlLabel
              key={category.name}
              control={
                <Checkbox
                  checked={visibleCategories.includes(category.name)}
                  onChange={() => handleCategoryToggle(category.name)}
                  sx={{
                    color: COLORS[index % COLORS.length],
                    '&.Mui-checked': {
                      color: COLORS[index % COLORS.length],
                    },
                  }}
                />
              }
              label={category.name}
              sx={{
                color: COLORS[index % COLORS.length],
                marginRight: 2,
                whiteSpace: 'nowrap', // Prevents text wrapping for each label
              }}
            />
          ))}
        </Box>

      </Box>

      <Box>
        <Typography variant="h6">Positives Heatmap</Typography>
        <Typography variant="h7">Shows average importance from 1 (not important) to 5 (very important)</Typography>
        <br /><br />
        <PositivesHeatmap filteredData={filteredData} />
      </Box>

      <br />
      <br />

      <Box>
        <Typography variant="h6">Drawbacks Heatmap</Typography>
        <Typography variant="h7">Shows average importance from 1 (not important) to 5 (very important)</Typography>
        <br /><br />
        <DrawbacksHeatmap filteredData={filteredData} />
      </Box>

      <br />
      <br />

      <ChosenSolutionsBubbleCharts filteredData={filteredData} />

      <br />
      <br />

      {/* Second Row: Single Column with Satisfaction Data Table */}
      <Box>
        <Typography variant="h6">Satisfaction Data</Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Solution Choice Category</InputLabel>
          <Select
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value);
              setPage(1);
            }}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {solutionData.map((item) => (
              <MenuItem key={item.name} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Satisfaction Data Table */}
        <Box height={400}>
          <DataGrid
            rows={filteredTableData.map((item, index) => ({
              id: index,
              ...item,  // Spread the full customer item so that all fields are available
              //customer_name: item.customer_name,  // Add customer_name here
              conversation_date: new Date(item.conversation_date).toLocaleDateString(),
              //solution_choice: item.solution_choice,
              //satisfaction_level: item.satisfaction_level,
              //satisfaction_level_justification: item.satisfaction_level_justification,
              //solution_choice_category: item.solution_choice_category,
            }))}
            columns={[
              { 
                field: 'customer_name', headerName: 'Name', flex: 1, 
                renderCell: (params) => (
                  <Typography
                    sx={{ cursor: 'pointer' }}  // Style as clickable text
                    onClick={() => handleRowClick(params.row)}  // Handle row click to open modal
                  >
                    {params.row.customer_name}
                  </Typography>
                )
              },
              { field: 'conversation_date', headerName: 'Conversation Date', flex: 1, sortable: true },
              { field: 'solution_choice', headerName: 'Solution Choice', flex: 1 },
              { field: 'satisfaction_level', headerName: 'Satisfaction Level', flex: 1, sortable: true },
              { field: 'satisfaction_level_justification', headerName: 'Satisfaction Justification', flex: 2 },
              { field: 'solution_choice_category', headerName: 'Solution Choice Category', flex: 1 },
            ]}
            pageSize={ITEMS_PER_PAGE}
            rowsPerPageOptions={[5, 10, 20]}
            sortingOrder={['asc', 'desc']}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            pagination
            paginationMode="client"
            rowCount={totalTablePages * ITEMS_PER_PAGE}
            page={page - 1}
            onPageChange={(params) => setPage(params + 1)}
          />
        </Box>

        {/* Customer Modal */}
        <CustomerModal
          open={modalOpen}
          onClose={handleModalClose}
          customer={selectedCustomer}
        />
        
      </Box>


    </Box>
  );
}

export default CurrentSolutions;

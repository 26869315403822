import React, { useState, useEffect, useContext } from 'react';
import {
    Dialog, DialogTitle, DialogContent, Box, Chip, TextField, Button, CircularProgress
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { DataAndFilterContext } from '../context/DataAndFilterContext';

function CustomTagModal({ open, onClose, dataFile, availableTags = [] }) {
    const { fetchUniqueTags, updateDataFile, filteredData } = useContext(DataAndFilterContext);
    const [tags, setTags] = useState([]);
    const [newTag, setNewTag] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [localAvailableTags, setLocalAvailableTags] = useState([]);

    useEffect(() => {
        if (open) {
            console.log("Modal opened, syncing tags with context");
            syncTagsWithContext();
        }
    }, [open, dataFile, filteredData, availableTags]);

    const syncTagsWithContext = () => {
        console.log("Syncing tags with context");
        const updatedFile = filteredData.find(f => f.filename === dataFile.filename);
        if (updatedFile) {
            console.log("Updated file found in context:", updatedFile);
            setTags(updatedFile.segmentation_data?.custom_tags || []);
        } else {
            console.log("Updated file not found in context");
        }
        setLocalAvailableTags([...new Set(availableTags)]);
        console.log("Initial local available tags set:", [...new Set(availableTags)]);
    };

    const getToken = () => localStorage.getItem('access_token');

    const handleTagOperation = async (url, method, body, onSuccess) => {
        console.log(`Starting ${method} operation for URL: ${url} with body:`, body);
        setIsProcessing(true);
        try {
            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify(body),
            });

            if (response.ok) {
                const responseData = await response.json();
                console.log("Response data from server:", responseData);
                const updatedFiles = responseData.updated_files || [responseData.updated_file];

                updatedFiles.forEach(file => {
                    console.log("Updating context for file:", file.filename);
                    updateDataFile(file.filename, file);
                });

                const currentFile = updatedFiles.find(f => f.filename === dataFile.filename);
                if (currentFile) {
                    console.log("Current file found and updating tags:", currentFile);
                    setTags(currentFile.segmentation_data?.custom_tags || []);
                } else {
                    console.log("Current file not found in updated files");
                }

                if (onSuccess) {
                    onSuccess(responseData);
                }

                // Refresh local available tags list
                const uniqueTags = await fetchUniqueTags();
                console.log("Fetched unique tags from context:", uniqueTags);
                setLocalAvailableTags([...new Set(uniqueTags)]);
            } else {
                console.error(`Failed ${method} operation at ${url}:`, response.status);
            }
        } catch (error) {
            console.error(`Error during ${method} operation at ${url}:`, error);
        } finally {
            setIsProcessing(false);
        }
    };

    const handleAddTag = (tag) => {
        console.log("Adding tag:", tag);
        handleTagOperation('/api/add-tag', 'POST', { filename: dataFile.filename, tag }, () => {
            console.log("Tag added successfully, updating local state");
            setTags(prev => [...new Set([...prev, tag])]);
            setLocalAvailableTags(prev => [...new Set([...prev, tag])]);
        });
    };

    const handleRemoveTag = (tag) => {
        console.log("Removing tag:", tag);
        handleTagOperation('/api/remove-tag', 'POST', { filename: dataFile.filename, tag }, () => {
            setTags(prev => prev.filter(t => t !== tag));
        });
    };

    const handleEditTag = (oldTag, newTagValue) => {
        if (!newTagValue.trim() || oldTag === newTagValue) return;
        console.log(`Editing tag from "${oldTag}" to "${newTagValue}"`);
        handleTagOperation('/api/edit-tag', 'POST', { old_tag: oldTag, new_tag: newTagValue }, () => {
            setTags(prev => prev.map(t => (t === oldTag ? newTagValue : t)));
            setLocalAvailableTags(prev => prev.map(t => (t === oldTag ? newTagValue : t)));
        });
    };

    const handleDeleteTag = (tag) => {
        if (window.confirm("Are you sure you want to delete this tag from all files that have this tag?")) {
            console.log("Deleting tag:", tag);
            handleTagOperation('/api/delete-tag', 'POST', { tag }, () => {
                setTags(prev => prev.filter(t => t !== tag));
                setLocalAvailableTags(prev => prev.filter(t => t !== tag));
            });
        }
    };

    const handleNewTagSubmit = (e) => {
        e.preventDefault(); // Prevent any default form submission behavior
        if (newTag.trim() && !tags.includes(newTag)) {
            console.log("Submitting new tag:", newTag);
            handleAddTag(newTag);
            setNewTag(''); // Clear input after adding
        } else {
            console.log("New tag is either empty or already exists");
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Edit Custom Tags</DialogTitle>
            <DialogContent>
                <Box mb={2}>
                    {localAvailableTags.map((tag, index) => (
                        <Chip
                            key={index}
                            label={tag}
                            onClick={() => {
                                if (tags.includes(tag)) {
                                    handleRemoveTag(tag);
                                } else {
                                    handleAddTag(tag);
                                }
                            }}
                            onDelete={() => handleDeleteTag(tag)}
                            icon={
                                <EditIcon
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent triggering other click events
                                        const newTagValue = prompt('Edit tag:', tag);
                                        if (newTagValue) handleEditTag(tag, newTagValue);
                                    }}
                                />
                            }
                            color={tags.includes(tag) ? 'primary' : 'default'}
                            clickable
                            style={{ margin: '4px' }}
                        />
                    ))}
                </Box>
                <form onSubmit={handleNewTagSubmit}>
                    <TextField
                        label="New Tag"
                        value={newTag}
                        onChange={(e) => setNewTag(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isProcessing || !newTag.trim()}
                    >
                        {isProcessing ? <CircularProgress size={24} /> : 'Add Tag'}
                    </Button>
                </form>
            </DialogContent>
        </Dialog>
    );
}

export default CustomTagModal;

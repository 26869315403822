import React from 'react';
import { Modal, Box, Typography } from '@mui/material';

function CustomerModal({ open, onClose, customer }) {
  if (!customer) return null;

  // Combine the insights from all the jobs to be done objects and the miscellaneous insights array
  const getCombinedInsights = (customer) => {
    const insights = [];
    if (customer.jobs_to_be_done) {
      customer.jobs_to_be_done.forEach(job => {
        if (job.insights) {
          insights.push(...job.insights);
        }
      });
    }
    if (customer.miscellaneous_insights) {
      insights.push(...customer.miscellaneous_insights);
    }
    return insights;
  };
  
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80vw',
          maxHeight: '80vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
        }}
      >
        <Typography variant="h4" component="h2" gutterBottom>
          <strong>{customer.customer_name}</strong>
        </Typography>
        <Typography variant="body2">
          <strong>Conversation Date:</strong> {customer.conversation_date}
        </Typography>
        <Typography variant="body2">
          <strong>About Me:</strong> {customer.about_me}
        </Typography>
        <Typography variant="body2">
          <strong>Conversation Source:</strong> {customer.conversation_source}
        </Typography>
        <Typography variant="body2">
          <strong>Persona:</strong> {customer.segmentation_data?.persona}
        </Typography>
        <br />
        <hr />
        <br />

        <Typography variant="h5" gutterBottom><strong>Buying Behaviors</strong></Typography>
        <br />
        <Typography variant="body2">
          <strong>Trigger for Needing New Solution:</strong> {customer.trigger}
        </Typography>
        <Typography variant="body2">
          <strong>Trigger Category:</strong> {customer.trigger_category}
        </Typography>
        <br />
        <br />

        <Typography variant="h7" gutterBottom><strong>Previous Solution</strong></Typography><br /><br />
        <Typography variant="body2">
          <strong>Previous Solution:</strong> {customer.previous_solution}
        </Typography>
        <Typography variant="body2">
          <strong>Previous Solution Category:</strong> {customer.previous_solution_category}
        </Typography>
        <Typography variant="body2">
          <strong>Stickiness Level of Previous Solution:</strong> {customer.stickiness_level_of_previous_solution}
        </Typography>
        <Typography variant="body2">
          <strong>Stickiness Level Justification:</strong> {customer.stickiness_level_of_previous_solution_justification}
        </Typography>
        <Typography variant="body2">
          <strong>Stickiness Level Quote:</strong> <em>"{customer.stickiness_level_of_previous_solution_quote}"</em>
        </Typography>
        <br />

        {/* Considered Solutions Section */}
        <Typography variant="h7" gutterBottom><strong>Considered Solutions</strong></Typography><br /><br />
        {customer.considered_solutions?.map((solution, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <Typography variant="body2"><strong>Considered Solution:</strong> {solution.considered_solution}</Typography>
            <Typography variant="body2"><strong>Considered Solution Category:</strong> {solution.considered_solution_category}</Typography>
            <br />
            <div style={{ marginLeft: '20px' }}>
              {/* Reasons Not Chosen */}
              <Typography variant="h7" gutterBottom>Reasons Not Chosen</Typography>
              {solution.reasons_not_chosen?.map((reason, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Typography variant="body2"><strong>Reason not chosen:</strong> {reason.reason_not_chosen}</Typography>
                  <Typography variant="body2"><strong>Reason not chosen category:</strong> {reason.reason_not_chosen_category}</Typography>
                  <Typography variant="body2"><strong>Importance:</strong> {reason.reason_not_chosen_importance}</Typography>
                  <Typography variant="body2"><strong>Justification:</strong> {reason.reason_not_chosen_importance_justification}</Typography>
                  <Typography variant="body2"><strong>Reason not chosen quote:</strong> <em>"{reason.reason_not_chosen_quote}"</em></Typography>
                  <Typography variant="body2"><strong>Timestamps:</strong> <a href=''>{reason.reason_not_chosen_timestamps}</a></Typography>
                </Box>
              ))}
            </div>
          </Box>
        ))}
        <br />

        {/* Chosen Solution Section */}
        <Typography variant="h7" gutterBottom><strong>Chosen Solution</strong></Typography><br /><br />
        <Typography variant="body2">
          <strong>Solution Choice:</strong> {customer.solution_choice}
        </Typography>
        <Typography variant="body2">
          <strong>Solution Choice Category:</strong> {customer.solution_choice_category}
        </Typography>
        <Typography variant="body2">
          <strong>Satisfaction Level:</strong> {customer.satisfaction_level}
        </Typography>
        <Typography variant="body2">
          <strong>Satisfaction Level Justification:</strong> {customer.satisfaction_level_justification}
        </Typography>
        <Typography variant="body2">
          <strong>Satisfaction Level Quote:</strong> <em>"{customer.satisfaction_level_quote}"</em>
        </Typography>
        <br />

        <div style={{ marginLeft: '20px' }}>
          {/* Positives of Solution Choice */}
          <Typography variant="h7" gutterBottom>Positives of Chosen Solution</Typography>
          <br />
          {customer.positives_of_solution_choice?.map((positive, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Typography variant="body2"><strong>Positive:</strong> {positive.positive}</Typography>
              <Typography variant="body2"><strong>Category:</strong> {positive.positive_category}</Typography>
              <Typography variant="body2"><strong>Importance:</strong> {positive.positive_importance}</Typography>
              <Typography variant="body2"><strong>Justification:</strong> {positive.positive_importance_justification}</Typography>
              <Typography variant="body2"><strong>Positive Importance Quote:</strong> <em>"{positive.positive_importance_quote}"</em></Typography>
            </Box>
          ))}

          {/* Drawbacks of Solution Choice */}
          <Typography variant="h7" gutterBottom>Drawbacks of Chosen Solution</Typography>
          <br />
          {customer.drawbacks_of_solution_choice?.map((drawback, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Typography variant="body2"><strong>Drawback:</strong> {drawback.drawback}</Typography>
              <Typography variant="body2"><strong>Category:</strong> {drawback.drawback_category}</Typography>
              <Typography variant="body2"><strong>Importance:</strong> {drawback.drawback_importance}</Typography>
              <Typography variant="body2"><strong>Justification:</strong> {drawback.drawback_importance_justification}</Typography>
              <Typography variant="body2"><strong>Drawback Importance Quote:</strong> <em>"{drawback.drawback_importance_quote}"</em></Typography>
            </Box>
          ))}
        </div>
        <br />
        <hr />
        <br />

        {/* Needs Section */}
        <Typography variant="h5" gutterBottom><strong>Needs</strong></Typography>
        <br />
        {customer.jobs_to_be_done?.map((job, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <Typography variant="h7" gutterBottom><strong>Job To Be Done:</strong> {job.raw_job_to_be_done}</Typography>
            <br />
            <div style={{ marginLeft: '20px' }}>
              <Typography variant="body2"><strong>Importance:</strong> {job.importance} of 10</Typography>
              <Typography variant="body2"><strong>Satisfaction:</strong> {job.satisfaction} of 10</Typography>
              <Typography variant="body2"><strong>Satisfaction Justification:</strong> {job.satisfaction_justification}</Typography>
              <Typography variant="body2"><strong>How They Measure Satisfaction:</strong> {job.how_they_measure_satisfaction}</Typography>
              <Typography variant="body2"><strong>Situation Category ("When..."):</strong> {job.situation_category}</Typography>
              <Typography variant="body2"><strong>Job Category ("I want to..."):</strong> {job.job_to_be_done_category}</Typography>
              <Typography variant="body2"><strong>Desired Outcome Category ("So I can..."):</strong> {job.desired_outcome_category}</Typography>
              <Typography variant="body2"><strong>Satisfaction Quote:</strong> <em>"{job.satisfaction_quote}"</em></Typography>
            </div>
            <br />
          </Box>
        ))}
        <br />
        <hr />
        <br />

        {/* Insights Section */}
        <Typography variant="h5" gutterBottom><strong>Insights</strong></Typography>
        <br />
        {getCombinedInsights(customer).map((insight, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <Typography variant="h7" gutterBottom><strong>Insight:</strong> {insight.insight}</Typography>
            <br />
            <div style={{ marginLeft: '20px' }}>
              <Typography variant="body2"><strong>Quote:</strong> <em>"{insight.insight_quote}"</em></Typography>
              <Typography variant="body2"><strong>Description:</strong> {insight.insight_summary}</Typography>
              <Typography variant="body2"><strong>Sentiment Overall:</strong> {insight.insight_sentiment_overall}</Typography>
              <Typography variant="body2"><strong>Sentiment Expressed:</strong> {insight.insight_sentiment_expressed}</Typography>
              <Typography variant="body2"><strong>Sentiment Score (5 is Neutral):</strong> {insight.insight_sentiment_score}</Typography>
              <Typography variant="body2"><strong>Sentiment Score Justification:</strong> {insight.insight_sentiment_justification}</Typography>
              <Typography variant="body2"><strong>Timestamps:</strong> {insight.insight_timestamps}</Typography>
              <Typography variant="body2"><strong>Category:</strong> {insight.insight_category}</Typography>
              <Typography variant="body2"><strong>Product Area:</strong> {insight.insight_product_area}</Typography>
              <Typography variant="body2"><strong>Insight Type:</strong> {insight.insight_type}</Typography>
            </div>
            <br />
          </Box>
        ))}
      </Box>
    </Modal>
  );
}

export default CustomerModal;

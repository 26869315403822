import React, { useState, useEffect, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { DataAndFilterContext } from '../../context/DataAndFilterContext';
import { Box, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import CustomerModal from '../CustomerModal';  // Import the modal

function TriggerTable() {
  const { filteredData } = useContext(DataAndFilterContext);
  const [tableData, setTableData] = useState([]);
  const [filterCategory, setFilterCategory] = useState('');
  const [uniqueCategories, setUniqueCategories] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);  // Modal state
  const [selectedCustomer, setSelectedCustomer] = useState(null);  // Selected customer data

  // Filter table data based on selected solution_choice_category
  const applyCategoryFilter = (category) => {
    if (category) {
      setTableData(filteredData.filter((item) => item.solution_choice_category === category));
    } else {
      setTableData(filteredData);
    }
  };

  // Process data and set unique solution_choice_category values
  useEffect(() => {
    const categories = [...new Set(filteredData.map(item => item.solution_choice_category))];
    setUniqueCategories(categories);
    setTableData(filteredData);
  }, [filteredData]);

  const handleRowClick = (customer) => {
    setSelectedCustomer(customer);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const columns = [
    { field: 'customer_name', headerName: 'Name', flex: 1, renderCell: (params) => (
        <Typography
          sx={{ cursor: 'pointer' }}
          onClick={() => handleRowClick(params.row)}  // Handle row click to open modal
        >
          {params.row.customer_name}
        </Typography>
    ) 
    },
    { field: 'trigger', headerName: 'Trigger', flex: 1 },
    { field: 'trigger_category', headerName: 'Trigger Category', flex: 1 },
    { field: 'conversation_date', headerName: 'Conversation Date', flex: 1 },
    { field: 'solution_choice', headerName: 'Solution Choice', flex: 1 },
    { field: 'solution_choice_category', headerName: 'Solution Choice Category', flex: 1 },
  ];

  const rows = tableData.map((item, index) => ({
    id: index,
    ...item,
    conversation_date: new Date(item.conversation_date).toLocaleDateString(),
  }));

  return (
    <Box>
      {/* Filter by Solution Choice Category */}
      <FormControl fullWidth margin="normal">
        <InputLabel>Solution Choice Category</InputLabel>
        <Select
          value={filterCategory}
          onChange={(e) => {
            setFilterCategory(e.target.value);
            applyCategoryFilter(e.target.value);
          }}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {uniqueCategories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Display the DataGrid Table */}
      <Box height={400}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          sortModel={[{ field: 'conversation_date', sort: 'desc' }]}
        />
      </Box>

      {/* Customer Modal */}
      <CustomerModal
        open={modalOpen}
        onClose={handleModalClose}
        customer={selectedCustomer}
      />
    </Box>
  );
}

export default TriggerTable;

// GlobalLoadingContext.js
import React, { createContext, useState, useContext } from 'react';

const GlobalLoadingContext = createContext();

export const GlobalLoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  return (
    <GlobalLoadingContext.Provider value={{ loading, startLoading, stopLoading }}>
      {children}
    </GlobalLoadingContext.Provider>
  );
};

export const useGlobalLoading = () => useContext(GlobalLoadingContext);

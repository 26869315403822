import React, { useContext } from 'react';
import {
  Box, Typography
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import NavBar from './components/NavBar';
import FilterBar from './components/FilterBar';
import theme from './theme';
import { DataAndFilterContext } from './context/DataAndFilterContext';
import PreviousSolutions from './components/data_visualizations/PreviousSolutions';  // Import the new component

function PreviousSolutionsPage() {
  const { loading } = useContext(DataAndFilterContext);  // Get filtered conversations from context

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Buying Behaviors
        </Typography>
        <FilterBar />
        
        <Box my={4}>
          <PreviousSolutions />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default PreviousSolutionsPage;

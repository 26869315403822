import React, { useContext, useState, useMemo } from 'react';
import {
  Box, Typography, FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DataAndFilterContext } from '../../context/DataAndFilterContext';
import CustomerModal from '../CustomerModal';

const ITEMS_PER_PAGE = 10;

function NeedsSatisfactionAndImportanceDataTable() {
  const { filteredData } = useContext(DataAndFilterContext);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [page, setPage] = useState(1);
  const [sortModel, setSortModel] = useState([{ field: 'conversation_date', sort: 'desc' }]);
  const [modalOpen, setModalOpen] = useState(false);  // State for modal visibility
  const [selectedCustomer, setSelectedCustomer] = useState(null);  // State for selected customer

  // Extract unique job_to_be_done categories from the filtered data
  const jobCategories = useMemo(() => {
    const categories = new Set();
    filteredData.forEach((file) => {
      file.jobs_to_be_done.forEach((job) => {
        categories.add(job.job_to_be_done_category);
      });
    });
    return Array.from(categories);
  }, [filteredData]);

  // Filter and transform data for the table
  const tableData = useMemo(() => {
    const data = filteredData.flatMap((file) =>
      file.jobs_to_be_done.map((job) => ({
        id: `${file.customer_name}-${file.conversation_date}-${job.job_to_be_done_category}`,
        customer_name: file.customer_name,
        conversation_date: new Date(file.conversation_date).toLocaleDateString(),
        raw_job_to_be_done: job.raw_job_to_be_done,
        importance: job.importance,
        satisfaction: job.satisfaction,
        how_they_measure_satisfaction: job.how_they_measure_satisfaction,
        satisfaction_justification: job.satisfaction_justification,
        situation_category: job.situation_category,
        job_to_be_done_category: job.job_to_be_done_category,
        desired_outcome_category: job.desired_outcome_category,
        fullCustomerData: file, // Include full customer data for modal
      }))
    );
    return selectedCategory
      ? data.filter((item) => item.job_to_be_done_category === selectedCategory)
      : data;
  }, [filteredData, selectedCategory]);

  const totalPages = Math.ceil(tableData.length / ITEMS_PER_PAGE);

  // Function to handle row click and open modal
  const handleRowClick = (customerData) => {
    setSelectedCustomer(customerData.fullCustomerData);
    setModalOpen(true);
  };

  // Function to handle modal close
  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>Needs, Satisfaction, and Importance Data</Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Job To Be Done Category</InputLabel>
        <Select
          value={selectedCategory}
          onChange={(e) => {
            setSelectedCategory(e.target.value);
            setPage(1); // Reset to the first page when the filter changes
          }}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {jobCategories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box height={500}>
        <DataGrid
          rows={tableData}
          columns={[
            {
              field: 'customer_name',
              headerName: 'Customer Name',
              flex: 1,
              sortable: true,
              renderCell: (params) => (
                <Typography
                  sx={{ cursor: 'pointer', color: 'blue' }}
                  onClick={() => handleRowClick(params.row)}
                >
                  {params.value}
                </Typography>
              ),
            },
            { field: 'conversation_date', headerName: 'Conversation Date', flex: 1, sortable: true },
            { field: 'raw_job_to_be_done', headerName: 'Job To Be Done', flex: 2, sortable: false },
            { field: 'importance', headerName: 'Importance', flex: 1, sortable: true },
            { field: 'satisfaction', headerName: 'Satisfaction', flex: 1, sortable: true },
            { field: 'how_they_measure_satisfaction', headerName: 'How Satisfaction is Measured', flex: 2, sortable: false },
            { field: 'satisfaction_justification', headerName: 'Satisfaction Justification', flex: 2, sortable: false },
            { field: 'situation_category', headerName: 'Situation Category', flex: 1, sortable: true },
            { field: 'job_to_be_done_category', headerName: 'Job To Be Done Category', flex: 1, sortable: true },
            { field: 'desired_outcome_category', headerName: 'Desired Outcome Category', flex: 1, sortable: true },
          ]}
          pageSize={ITEMS_PER_PAGE}
          rowsPerPageOptions={[5, 10, 20]}
          sortingOrder={['asc', 'desc']}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          pagination
          paginationMode="client"
          rowCount={tableData.length}
          page={page - 1}
          onPageChange={(params) => setPage(params + 1)}
        />
      </Box>

      {/* Customer Modal */}
      <CustomerModal
        open={modalOpen}
        onClose={handleModalClose}
        customer={selectedCustomer}
      />
    </Box>
  );
}

export default NeedsSatisfactionAndImportanceDataTable;

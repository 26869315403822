import React, { useContext, useState, useMemo } from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DataAndFilterContext } from '../../context/DataAndFilterContext';
import CustomerModal from '../CustomerModal';

const ITEMS_PER_PAGE = 10;

function ConsideredSolutionsTable() {
  const { filteredData } = useContext(DataAndFilterContext);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [page, setPage] = useState(1);
  const [sortModel, setSortModel] = useState([{ field: 'conversation_date', sort: 'desc' }]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  // Filter and format the table data based on selected category
  const tableData = useMemo(() => {
    return filteredData
      .filter((item) => 
        (selectedCategory ? item.considered_solutions.some(sol => sol.considered_solution_category === selectedCategory) : true)
      )
      .flatMap((item) => 
        item.considered_solutions.flatMap((sol) =>
          sol.reasons_not_chosen.map((reason, index) => ({
            id: `${item.customer_name}-${index}`, // Unique id for each row
            customer_name: item.customer_name,
            conversation_date: new Date(item.conversation_date).toLocaleDateString(),
            considered_solution: sol.considered_solution,
            considered_solution_category: sol.considered_solution_category,
            reason_not_chosen: reason.reason_not_chosen,
            reason_not_chosen_category: reason.reason_not_chosen_category,
            reason_not_chosen_importance: reason.reason_not_chosen_importance,
            reason_not_chosen_importance_justification: reason.reason_not_chosen_importance_justification,
            reason_not_chosen_timestamps: reason.reason_not_chosen_timestamps,
            fullData: item, // Pass full data for modal use
          }))
        )
      );
  }, [filteredData, selectedCategory]);

  const handleRowClick = (customer) => {
    setSelectedCustomer(customer);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6" gutterBottom>
        Reasons Not Chosen Data
      </Typography>
      
      {/* Selector for considered solution category */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Considered Solution Category</InputLabel>
        <Select
          value={selectedCategory}
          onChange={(e) => {
            setSelectedCategory(e.target.value);
            setPage(1);
          }}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {Array.from(new Set(filteredData.flatMap((item) =>
            item.considered_solutions.map(sol => sol.considered_solution_category)
          ))).map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* DataGrid for displaying the table */}
      <Box height={400}>
        <DataGrid
          rows={tableData}
          columns={[
            {
              field: 'customer_name', headerName: 'Customer Name', flex: 1,
              renderCell: (params) => (
                <Typography
                  sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => handleRowClick(params.row.fullData)}
                >
                  {params.row.customer_name}
                </Typography>
              )
            },
            { field: 'conversation_date', headerName: 'Conversation Date', flex: 1, sortable: true },
            { field: 'considered_solution', headerName: 'Considered Solution', flex: 1 },
            { field: 'considered_solution_category', headerName: 'Category', flex: 1 },
            { field: 'reason_not_chosen', headerName: 'Reason Not Chosen', flex: 2 },
            { field: 'reason_not_chosen_category', headerName: 'Reason Category', flex: 1 },
            { field: 'reason_not_chosen_importance', headerName: 'Importance', flex: 1, sortable: true },
            { field: 'reason_not_chosen_importance_justification', headerName: 'Importance Justification', flex: 2 },
            { field: 'reason_not_chosen_timestamps', headerName: 'Timestamps', flex: 1 },
          ]}
          pageSize={ITEMS_PER_PAGE}
          rowsPerPageOptions={[5, 10, 20]}
          sortingOrder={['asc', 'desc']}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          pagination
          paginationMode="client"
          rowCount={tableData.length}
          page={page - 1}
          onPageChange={(params) => setPage(params + 1)}
        />
      </Box>

      {/* Customer Modal */}
      <CustomerModal
        open={modalOpen}
        onClose={handleModalClose}
        customer={selectedCustomer}
      />
    </Box>
  );
}

export default ConsideredSolutionsTable;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';

const ThankYouPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/dashboard'); // Replace '/dashboard' with your desired route
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Thank You!
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 3 }}>
        We appreciate your time and effort in completing the interview. Your input is invaluable.
      </Typography>
    </Box>
  );
};

export default ThankYouPage;

import React, { useState } from 'react';
import {
  Box, Typography, TextField, Button, Alert, List, ListItem, ListItemIcon, ListItemText, CircularProgress, Snackbar
} from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import NavBar from './components/NavBar';
import theme from './theme';
import { useParams } from 'react-router-dom';

function IntegrationSetup() {
  const { integrationName } = useParams();
  const [authDetails, setAuthDetails] = useState({ apiKey: '' });
  const [setupStatus, setSetupStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stepsCompleted, setStepsCompleted] = useState([false, false]); // Assume two setup steps for simplicity

  const handleInputChange = (e) => {
    setAuthDetails({ ...authDetails, [e.target.name]: e.target.value });
    if (e.target.value) {
      setStepsCompleted([true, stepsCompleted[1]]); // Mark the first step as completed
    }
  };

  const handleSetup = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch('/api/setup-integration', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          integrationName,
          authDetails,
        }),
      });

      if (response.ok) {
        setSetupStatus('Integration set up successfully!');
        setStepsCompleted([true, true]); // Mark all steps as completed
      } else {
        setSetupStatus('Failed to set up integration.');
      }
    } catch (err) {
      setSetupStatus('An error occurred during setup.');
    } finally {
      setLoading(false);
    }
  };

  // Mock descriptions (in real use, fetch from backend or define in config)
  const integrationDescriptions = {
    "Gong": "This integration will automatically pull in new sales call recordings and analyze them for insights.",
    "Chorus.ai": "Connect Chorus.ai to import and process call recordings automatically after each conversation.",
    "Zendesk": "Integrate with Zendesk to sync new customer support tickets and analyze interactions for trends.",
    "Google Drive": "Monitor a specified folder in Google Drive to automatically pull in and process new video recordings."
  };

  const description = integrationDescriptions[integrationName] || "Connect this integration to enhance your workflow.";

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Set Up {decodeURIComponent(integrationName)} Integration
        </Typography>
        <Typography gutterBottom>
          {description}
        </Typography>

        {/* Integration Checklist Header */}
        <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 1 }}>
          Integration Checklist
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              {stepsCompleted[0] ? <CheckCircle color="success" /> : <RadioButtonUnchecked />}
            </ListItemIcon>
            <ListItemText primary="Enter API Key" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              {stepsCompleted[1] ? <CheckCircle color="success" /> : <RadioButtonUnchecked />}
            </ListItemIcon>
            <ListItemText primary="Submit Integration Setup" />
          </ListItem>
        </List>

        <Typography variant="caption" sx={{ marginBottom: 1 }}>
          Your API Key is required to authenticate with the integration platform.{' '}
          <a href="https://example.com/docs" target="_blank" rel="noopener noreferrer">Learn more.</a>
        </Typography>
        <TextField
          label="API Key"
          name="apiKey"
          variant="outlined"
          fullWidth
          value={authDetails.apiKey}
          onChange={handleInputChange}
          sx={{ marginBottom: 2 }}
        />
        <Button
          variant="contained"
          onClick={handleSetup}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Set Up Integration'}
        </Button>
        {setupStatus && (
          <Alert severity={setupStatus.includes('successfully') ? 'success' : 'error'} sx={{ marginTop: 2 }}>
            {setupStatus}
          </Alert>
        )}
        <Snackbar
          open={setupStatus && setupStatus.includes('successfully')}
          autoHideDuration={6000}
          message="Integration set up successfully!"
          onClose={() => setSetupStatus(null)}
        />
      </Box>
    </ThemeProvider>
  );
}

export default IntegrationSetup;

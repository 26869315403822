import React, { useContext } from 'react';
import {
  Box, Typography
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import NavBar from './components/NavBar';
import FilterBar from './components/FilterBar';
import theme from './theme';
import { DataAndFilterContext } from './context/DataAndFilterContext';

function InsightDetails() {
  const { filteredData, loading } = useContext(DataAndFilterContext);  // Get filtered conversations from context

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Hello world
        </Typography>
        <FilterBar />
      </Box>
    </ThemeProvider>
  );
  
}

export default InsightDetails;

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

function PublicRoute() {
    const token = localStorage.getItem('access_token');

    // If the token exists, redirect to the dashboard
    return token ? <Navigate to="/managedata" /> : <Outlet />;
}

export default PublicRoute;

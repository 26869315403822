import React, { useContext, useMemo } from 'react';
import { DataAndFilterContext } from '../../context/DataAndFilterContext';
import { ResponsiveContainer, ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, BarChart, Bar, Cell } from 'recharts';
import { Box, Typography, Grid } from '@mui/material';

// Helper function to calculate month labels
const getMonthLabel = (date) => {
  const options = { month: 'short', year: '2-digit' };
  return date.toLocaleDateString('en-US', options);
};

// Helper function to generate all months between two dates
const generateMonthRange = (startDate, endDate) => {
  const months = [];
  const current = new Date(startDate);
  
  while (current <= endDate) {
    months.push(getMonthLabel(current));
    current.setMonth(current.getMonth() + 1);
  }
  
  return months;
};

// Tooltip for Scatter Chart
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { avgImportance, avgSatisfaction, job, count } = payload[0].payload;
    return (
      <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
        <p><strong>Job:</strong> {job}</p>
        <p>Avg Satisfaction: {avgSatisfaction.toFixed(1)}</p>
        <p>Avg Importance: {avgImportance.toFixed(1)}</p>
        <p>Files Count: {count}</p>
      </div>
    );
  }
  return null;
};

function NeedsImportanceVsSatisfaction() {
  const { filteredData, filters } = useContext(DataAndFilterContext);

  // Calculate the date range based on filters
  const dateRange = useMemo(() => {
    const currentDate = new Date();
    let startDate;
    if (filters.dateRange === '3 months') {
      startDate = new Date(currentDate.setMonth(currentDate.getMonth() - 3));
    } else if (filters.dateRange === '6 months') {
      startDate = new Date(currentDate.setMonth(currentDate.getMonth() - 6));
    } else {
      startDate = new Date(Math.min(...filteredData.map((file) => new Date(file.conversation_date))));
    }
    const endDate = new Date();
    return { startDate, endDate };
  }, [filters.dateRange, filteredData]);

  // Calculate data for the scatter chart
  const scatterData = useMemo(() => {
    const jobStats = {};
    
    filteredData.forEach((file) => {
      file.jobs_to_be_done.forEach((job) => {
        const category = job.job_to_be_done_category;
        if (!jobStats[category]) {
          jobStats[category] = { totalImportance: 0, totalSatisfaction: 0, count: 0 };
        }
        jobStats[category].totalImportance += job.importance;
        jobStats[category].totalSatisfaction += job.satisfaction;
        jobStats[category].count += 1;
      });
    });

    return Object.keys(jobStats).map((job) => {
      const { totalImportance, totalSatisfaction, count } = jobStats[job];
      const avgImportance = totalImportance / count;
      const avgSatisfaction = totalSatisfaction / count;

      return {
        job,
        avgImportance,
        avgSatisfaction,
        count
      };
    });
  }, [filteredData]);

  // Calculate data for the bar chart
  const barData = useMemo(() => {
    const { startDate, endDate } = dateRange;
    const allMonths = generateMonthRange(startDate, endDate);

    const jobCategories = new Set();
    filteredData.forEach((file) => {
      file.jobs_to_be_done.forEach((job) => {
        jobCategories.add(job.job_to_be_done_category);
      });
    });

    const initialData = allMonths.map((month) => {
      const monthData = { month };
      jobCategories.forEach((category) => {
        monthData[category] = 0;
      });
      return monthData;
    });

    filteredData.forEach((file) => {
      const monthLabel = getMonthLabel(new Date(file.conversation_date));
      file.jobs_to_be_done.forEach((job) => {
        const category = job.job_to_be_done_category;
        const monthIndex = initialData.findIndex((data) => data.month === monthLabel);
        if (monthIndex !== -1) {
          initialData[monthIndex][category] += 1;
        }
      });
    });

    return initialData;
  }, [filteredData, dateRange]);

  return (
    <Box>
      <Grid container spacing={2}>
        {/* Left Grid Area for Scatter Chart */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Needs: Importance vs Satisfaction</Typography>
          <Typography variant="subtitle1">The importance vs satisfaction of the Jobs To Be Done</Typography>
          <ResponsiveContainer width="100%" height={400}>
            <ScatterChart>
              <CartesianGrid />
              <XAxis
                type="number"
                dataKey="avgSatisfaction"
                name="Satisfaction"
                domain={[1, 5]}  // Set fixed domain from 1 to 5
                ticks={[1, 2, 3, 4, 5]}  // Specify custom tick values
                label={{ value: 'Satisfaction (1-5)', position: 'insideBottom', offset: -5 }}
              />
              <YAxis
                type="number"
                dataKey="avgImportance"
                name="Importance"
                domain={['auto', 'auto']}
                tickFormatter={(tick) => tick.toFixed(1)}
                label={{ value: 'Importance (1-5)', angle: -90, position: 'insideLeft', offset: 0 }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Scatter
                name="Job Categories"
                data={scatterData}
                fill="#8884d8"
                shape="star"
              >
                {scatterData.map((entry, index) => (
                  <Cell key={`cell-${index}`} size={Math.max(40, entry.count * 40)} /> // Adjust size of stars based on file count of each star
                ))}
              </Scatter>
            </ScatterChart>
          </ResponsiveContainer>
        </Grid>

        {/* Right Grid Area for Bar Chart */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Needs over time</Typography>
          <Typography variant="subtitle1">Number of customers with each Job To Be Done over time</Typography>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={barData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis allowDecimals={false} />
              <Tooltip />
              {Object.keys(barData[0] || {}).filter(key => key !== 'month').map((category, index) => (
                <Bar key={category} dataKey={category} fill={`hsl(${index * 30}, 70%, 50%)`} />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

export default NeedsImportanceVsSatisfaction;

import React, { useMemo } from 'react';
import { Box, Typography, Grid } from '@mui/material';

// Helper function to calculate averages and conversation count
const calculateAverageAndCount = (data, solutionCategory, reasonCategory) => {
  const matchingItems = data
    .filter(
      (item) =>
        item.considered_solutions.some(
          (solution) => solution.considered_solution_category === solutionCategory &&
          solution.reasons_not_chosen.some(
            (reason) => reason.reason_not_chosen_category === reasonCategory
          )
        )
    )
    .flatMap((item) =>
      item.considered_solutions.flatMap((solution) =>
        solution.reasons_not_chosen.filter(
          (reason) => reason.reason_not_chosen_category === reasonCategory
        )
      )
    );

  if (matchingItems.length === 0) return { average: null, count: 0 };

  const totalImportance = matchingItems.reduce(
    (acc, reason) => acc + reason.reason_not_chosen_importance,
    0
  );

  const average = totalImportance / matchingItems.length;

  // Count the unique data files (conversations) that match both criteria
  const count = data.filter(
    (item) =>
      item.considered_solutions.some(
        (solution) => solution.considered_solution_category === solutionCategory &&
        solution.reasons_not_chosen.some(
          (reason) => reason.reason_not_chosen_category === reasonCategory
        )
      )
  ).length;

  return { average, count };
};

// Dynamic color mapping function (light-to-bright red gradient)
const getCellColor = (value, minValue, maxValue) => {
  if (value === null) return '#D3D3D3'; // Light grey for 'n/a'

  // Normalize the value based on the min and max values in the dataset
  const intensity = (value - minValue) / (maxValue - minValue);

  // Calculate RGB values for a light-to-bright red gradient
  const red = 255; // Full red value for a bright red
  const green = 255 - Math.round(intensity * 255); // High green value for light color (almost white)
  const blue = 255 - Math.round(intensity * 255); // High blue value for light color (almost white)

  return `rgb(${red}, ${green}, ${blue})`;
};

const ReasonsNotChosenHeatmap = ({ filteredData }) => {
  // Get unique considered_solution_category values (X-axis)
  const solutionCategories = [
    ...new Set(
      filteredData.flatMap((item) =>
        item.considered_solutions.map((solution) => solution.considered_solution_category)
      )
    ),
  ];

  // Get unique reason_not_chosen_category values (Y-axis)
  const reasonCategories = [
    ...new Set(
      filteredData.flatMap((item) =>
        item.considered_solutions.flatMap((solution) =>
          solution.reasons_not_chosen.map(
            (reason) => reason.reason_not_chosen_category
          )
        )
      )
    ),
  ];

  // Calculate min and max values from the data
  const { minValue, maxValue } = useMemo(() => {
    let min = Infinity;
    let max = -Infinity;

    filteredData.forEach((item) => {
      item.considered_solutions.forEach((solution) => {
        solution.reasons_not_chosen.forEach((reason) => {
          if (reason.reason_not_chosen_importance < min) min = reason.reason_not_chosen_importance;
          if (reason.reason_not_chosen_importance > max) max = reason.reason_not_chosen_importance;
        });
      });
    });

    return { minValue: min, maxValue: max };
  }, [filteredData]);

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6" gutterBottom>
        Importance of Reasons Not Chosen
      </Typography>
      <Typography variant="body2" gutterBottom>
        The reasons customers didn't choose each considered solution. Scored from 1 (not very important) to 5 (very important).
      </Typography>
      <br />
      <Grid container>
        {/* Header Row */}
        <Grid item xs={2}>
        </Grid>
        {solutionCategories.map((solution, index) => (
          <Grid item xs key={`header-${index}`}>
            <Typography variant="subtitle2" align="center">
              {solution}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {/* Data Rows */}
      {reasonCategories.map((reasonCategory, rowIndex) => (
        <Grid container key={`row-${rowIndex}`}>
          <Grid item xs={2}>
            <Typography variant="subtitle2">{reasonCategory}</Typography>
          </Grid>
          {solutionCategories.map((solutionCategory, colIndex) => {
            const { average, count } = calculateAverageAndCount(
              filteredData,
              solutionCategory,
              reasonCategory
            );
            const cellColor = getCellColor(average, minValue, maxValue);
            return (
              <Grid
                item
                xs
                key={`cell-${rowIndex}-${colIndex}`}
                style={{
                  backgroundColor: cellColor,
                  border: '1px solid #ccc',
                  padding: '8px',
                  textAlign: 'center',
                }}
              >
                {average !== null ? (
                  <>
                    <Typography variant="body2">{average.toFixed(2)}</Typography>
                    <Typography
                      variant="caption"
                      style={{ fontSize: '0.75rem' }}
                    >
                      ({count} {count === 1 ? 'conversation' : 'conversations'})
                    </Typography>
                  </>
                ) : (
                  'n/a'
                )}
              </Grid>
            );
          })}
        </Grid>
      ))}
    </Box>
  );
};

export default ReasonsNotChosenHeatmap;

import React, { useContext, useMemo, useState } from 'react';
import { DataAndFilterContext } from '../../context/DataAndFilterContext';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { Box, Typography, Grid, Checkbox, FormControlLabel, FormGroup } from '@mui/material';

// Helper function to calculate month labels
const getMonthLabel = (date) => {
  const options = { month: 'short', year: '2-digit' };
  return date.toLocaleDateString('en-US', options);
};

// Helper function to generate all months between two dates
const generateMonthRange = (startDate, endDate) => {
  const months = [];
  const current = new Date(startDate);
  
  while (current <= endDate) {
    months.push(getMonthLabel(current));
    current.setMonth(current.getMonth() + 1);
  }
  
  return months;
};

function NeedsImportanceAndSatisfactionOverTime() {
  const { filteredData, filters } = useContext(DataAndFilterContext);

  // Calculate the date range based on filters
  const dateRange = useMemo(() => {
    const currentDate = new Date();
    let startDate;
    if (filters.dateRange === '3 months') {
      startDate = new Date(currentDate.setMonth(currentDate.getMonth() - 3));
    } else if (filters.dateRange === '6 months') {
      startDate = new Date(currentDate.setMonth(currentDate.getMonth() - 6));
    } else {
      startDate = new Date(Math.min(...filteredData.map((file) => new Date(file.conversation_date))));
    }
    const endDate = new Date();
    return { startDate, endDate };
  }, [filters.dateRange, filteredData]);

  // Prepare line data for satisfaction and importance over time
  const { satisfactionData, importanceData, jobCategories, countsData } = useMemo(() => {
    const { startDate, endDate } = dateRange;
    const allMonths = generateMonthRange(startDate, endDate);

    const jobCategories = new Set();
    filteredData.forEach((file) => {
      file.jobs_to_be_done.forEach((job) => {
        jobCategories.add(job.job_to_be_done_category);
      });
    });

    const initialData = allMonths.map((month) => {
      const monthData = { month };
      jobCategories.forEach((category) => {
        monthData[category] = 1; // Initialize values to 0
      });
      return monthData;
    });

    const countsData = JSON.parse(JSON.stringify(initialData));

    const satisfactionData = JSON.parse(JSON.stringify(initialData));
    const importanceData = JSON.parse(JSON.stringify(initialData));

    filteredData.forEach((file) => {
      const monthLabel = getMonthLabel(new Date(file.conversation_date));
      file.jobs_to_be_done.forEach((job) => {
        const category = job.job_to_be_done_category;
        const monthIndex = satisfactionData.findIndex((data) => data.month === monthLabel);

        if (monthIndex !== -1) {
          satisfactionData[monthIndex][category] += job.satisfaction;
          importanceData[monthIndex][category] += job.importance;
          countsData[monthIndex][category] += 1;
        }
      });
    });

    satisfactionData.forEach((monthData, index) => {
      jobCategories.forEach((category) => {
        const count = countsData[index][category];
        if (count > 0) {
          monthData[category] = monthData[category] / count;
        }
      });
    });

    importanceData.forEach((monthData, index) => {
      jobCategories.forEach((category) => {
        const count = countsData[index][category];
        if (count > 0) {
          monthData[category] = monthData[category] / count;
        }
      });
    });

    return { satisfactionData, importanceData, jobCategories: Array.from(jobCategories), countsData };
  }, [filteredData, dateRange]);

  const [visibleLines, setVisibleLines] = useState(() =>
    jobCategories.reduce((acc, category) => ({ ...acc, [category]: true }), {})
  );

  const handleCheckboxChange = (category) => {
    setVisibleLines((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  // Custom tooltip formatter
  const customTooltipFormatter = (value, name, props) => {
    const { payload, dataKey } = props;
    const count = countsData.find((month) => month.month === payload.month)[dataKey];
    return `${value.toFixed(1)} (${count} conversations)`;  // Shows value and count in parentheses
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Satisfaction over time</Typography>
          <Typography variant="subtitle1">Average satisfaction with each Job To Be Done over time</Typography>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={satisfactionData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis
                domain={[1, 5]}
                ticks={[1, 2, 3, 4, 5]}
                tickFormatter={(tick) => {
                  if (tick === 1) return "1 not satisfied";
                  if (tick === 5) return "5 very satisfied";
                  return tick;
                }}
              />
              <Tooltip formatter={customTooltipFormatter} />
              {jobCategories.map((category, index) =>
                visibleLines[category] ? (
                  <Line
                    key={category}
                    type="monotone"
                    dataKey={category}
                    stroke={`hsl(${index * 30}, 70%, 50%)`}
                    strokeWidth={2}
                    name={category}
                  />
                ) : null
              )}
            </LineChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6">Importance over time</Typography>
          <Typography variant="subtitle1">Average importance of each Job To Be Done over time</Typography>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={importanceData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="month"   
              />
              <YAxis
                domain={[1, 5]}
                ticks={[1, 2, 3, 4, 5]}
                tickFormatter={(tick) => {
                  if (tick === 1) return "1 not important";
                  if (tick === 5) return "5 very important";
                  return tick;
                }}
              />
              <Tooltip formatter={customTooltipFormatter} />
              {jobCategories.map((category, index) =>
                visibleLines[category] ? (
                  <Line
                    key={category}
                    type="monotone"
                    dataKey={category}
                    stroke={`hsl(${index * 30}, 70%, 50%)`}
                    strokeWidth={2}
                    name={category}
                  />
                ) : null
              )}
            </LineChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>

      <Box mt={2}>
        <FormGroup row>
          {jobCategories.map((category, index) => (
            <FormControlLabel
              key={category}
              control={
                <Checkbox
                  checked={visibleLines[category]}
                  onChange={() => handleCheckboxChange(category)}
                  style={{ color: `hsl(${index * 30}, 70%, 50%)` }}
                />
              }
              label={category}
            />
          ))}
        </FormGroup>
      </Box>
    </Box>
  );
}

export default NeedsImportanceAndSatisfactionOverTime;

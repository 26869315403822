import React, { useMemo, useState } from 'react';
import { Box, Typography, Grid, Modal, Paper, Link } from '@mui/material';
import CustomerModal from '../CustomerModal'; // Ensure this component is correctly imported

// Helper function to calculate averages, conversation count, and retrieve positives
const calculateAverageAndCount = (data, solutionCategory, positiveCategory) => {
  const matchingItems = data
    .filter(
      (item) =>
        item.solution_choice_category === solutionCategory &&
        item.positives_of_solution_choice.some(
          (positive) => positive.positive_category === positiveCategory
        )
    )
    .flatMap((item) =>
      item.positives_of_solution_choice
        .filter((positive) => positive.positive_category === positiveCategory)
        .map((positive) => ({
          ...positive,
          customer_name: item.customer_name,
          customer_data: item,
        }))
    );

  if (matchingItems.length === 0) return { average: null, count: 0, positives: [] };

  const totalImportance = matchingItems.reduce(
    (acc, positive) => acc + positive.positive_importance,
    0
  );

  const average = totalImportance / matchingItems.length;

  return { average, count: matchingItems.length, positives: matchingItems };
};

const PositivesHeatmap = ({ filteredData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedPositives, setSelectedPositives] = useState([]);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const solutionCategories = [...new Set(filteredData.map((item) => item.solution_choice_category))];
  const positiveCategories = [
    ...new Set(
      filteredData.flatMap((item) =>
        item.positives_of_solution_choice.map((positive) => positive.positive_category)
      )
    ),
  ];

  const { minValue, maxValue } = useMemo(() => {
    let min = Infinity;
    let max = -Infinity;
    filteredData.forEach((item) => {
      item.positives_of_solution_choice.forEach((positive) => {
        if (positive.positive_importance < min) min = positive.positive_importance;
        if (positive.positive_importance > max) max = positive.positive_importance;
      });
    });
    return { minValue: min, maxValue: max };
  }, [filteredData]);

  const getCellColor = (value) => {
    if (value === null) return '#D3D3D3';
    const intensity = (value - minValue) / (maxValue - minValue);
    const red = 255 - Math.round(intensity * 155);
    const green = 255;
    const blue = 255 - Math.round(intensity * 155);
    return `rgb(${red}, ${green}, ${blue})`;
  };

  const handleCellClick = (positives) => {
    if (positives.length > 0) {
      setSelectedPositives(positives);
      setOpenModal(true);
    }
  };

  const handleClose = () => setOpenModal(false);
  const handleCustomerClick = (customer) => {
    setSelectedCustomer(customer);
    setCustomerModalOpen(true);
  };
  const handleCustomerModalClose = () => setCustomerModalOpen(false);

  return (
    <Box>
      <Grid container>
        <Grid item xs={2}></Grid>
        {solutionCategories.map((solution, index) => (
          <Grid item xs key={`header-${index}`}>
            <Typography variant="subtitle2" align="center">{solution}</Typography>
          </Grid>
        ))}
      </Grid>

      {positiveCategories.map((positiveCategory, rowIndex) => (
        <Grid container key={`row-${rowIndex}`}>
          <Grid item xs={2}>
            <Typography variant="subtitle2">{positiveCategory}</Typography>
          </Grid>
          {solutionCategories.map((solutionCategory, colIndex) => {
            const { average, count, positives } = calculateAverageAndCount(
              filteredData,
              solutionCategory,
              positiveCategory
            );
            const cellColor = getCellColor(average);

            return (
              <Grid
                item
                xs
                key={`cell-${rowIndex}-${colIndex}`}
                style={{
                  backgroundColor: cellColor,
                  border: '1px solid #ccc',
                  padding: '8px',
                  textAlign: 'center',
                  cursor: positives.length > 0 ? 'pointer' : 'default',
                }}
                onClick={() => handleCellClick(positives)}
              >
                {average !== null ? (
                  <>
                    <Typography variant="body2">{average.toFixed(2)}</Typography>
                    <Typography variant="caption" style={{ fontSize: '0.75rem' }}>
                      ({count} {count === 1 ? 'conversation' : 'conversations'})
                    </Typography>
                  </>
                ) : (
                  'n/a'
                )}
              </Grid>
            );
          })}
        </Grid>
      ))}

      <Modal open={openModal} onClose={handleClose}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%', bgcolor: 'background.paper', boxShadow: 24, p: 4, maxHeight: '80vh', overflowY: 'auto' }}>
          <Typography variant="h6">Positive Details</Typography>
          {selectedPositives.map((positive, index) => (
            <Paper key={index} sx={{ p: 2, mb: 2 }}>
              <Typography variant="body1" fontWeight="bold" gutterBottom>
                "{positive.positive_importance_quote}"
              </Typography>
              <Typography variant="h6" gutterBottom>{positive.positive}</Typography>

              <Box sx={{ mb: 2 }}>
                <Typography variant="body2">Category: {positive.positive_category}</Typography>
                <Typography variant="body2">Importance: {positive.positive_importance}</Typography>
                <Typography variant="body2">Justification: {positive.positive_importance_justification}</Typography>
                <Link href="#" variant="body2" underline="hover" sx={{ color: 'secondary.main', display: 'block', mb: 1 }}>
                  Timestamps: {positive.positive_timestamps}
                </Link>
              </Box>

              <Typography
                variant="body2"
                sx={{ cursor: 'pointer', color: 'primary.main', mt: 2 }}
                onClick={() => handleCustomerClick(positive.customer_data)}
              >
                Customer: {positive.customer_name}
              </Typography>
            </Paper>
          ))}
        </Box>
      </Modal>

      <CustomerModal open={customerModalOpen} onClose={handleCustomerModalClose} customer={selectedCustomer} />
    </Box>
  );
};

export default PositivesHeatmap;

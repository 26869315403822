import React, { useContext, useMemo } from 'react';
import { DataAndFilterContext } from '../../context/DataAndFilterContext';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell, LabelList } from 'recharts';
import { Box, Typography, Grid } from '@mui/material';

// Color range for bar charts
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#845EC2', '#D65DB1', '#FF6F91', '#FF9671'];

// Custom tooltip for bar charts
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { name, value, payload: item } = payload[0];
    return (
      <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '5px' }}>
        <p><strong>{name}</strong></p>
        <p>{`${item.percentage}% (${value} files)`}</p>
      </div>
    );
  }
  return null;
};

// Custom label content for displaying file count and percentage inside or outside the bars
const renderCustomLabel = (props) => {
  const { x, y, width, height, value, index, data } = props;
  const percentage = data[index]?.percentage || 0;
  const labelText = `${value} files (${percentage}%)`;

  // Check if there's enough room to display the label inside the bar
  const labelWidth = 100; // Approximate label width based on average character size
  const insideBar = width > labelWidth;

  return (
    <text
      x={insideBar ? x + 5 : x + width + 5}
      y={y + height / 2 + 5}
      fill={insideBar ? "#fff" : "#000"}
      fontSize={12}
      textAnchor="start"
    >
      {labelText}
    </text>
  );
};

function NeedsBarCharts() {
  const { filteredData } = useContext(DataAndFilterContext);

  // Helper function to calculate the percentage
  const calculatePercentage = (value, total) => Math.round((value / total) * 100);

  // Prepare data for each bar chart
  const { situationData, jobData, outcomeData } = useMemo(() => {
    const totalFiles = filteredData.length;
    const situationCount = {};
    const jobCount = {};
    const outcomeCount = {};

    filteredData.forEach((file) => {
      const uniqueSituations = new Set();
      const uniqueJobs = new Set();
      const uniqueOutcomes = new Set();

      file.jobs_to_be_done.forEach((job) => {
        if (job.situation_category) uniqueSituations.add(job.situation_category);
        if (job.job_to_be_done_category) uniqueJobs.add(job.job_to_be_done_category);
        if (job.desired_outcome_category) uniqueOutcomes.add(job.desired_outcome_category);
      });

      uniqueSituations.forEach((situation) => {
        situationCount[situation] = (situationCount[situation] || 0) + 1;
      });

      uniqueJobs.forEach((job) => {
        jobCount[job] = (jobCount[job] || 0) + 1;
      });

      uniqueOutcomes.forEach((outcome) => {
        outcomeCount[outcome] = (outcomeCount[outcome] || 0) + 1;
      });
    });

    // Transform and sort the counts into an array for the bar charts
    const transformAndSortData = (countObject) => 
      Object.entries(countObject)
        .map(([key, value]) => ({
          name: key,
          value,
          percentage: calculatePercentage(value, totalFiles),
        }))
        .sort((a, b) => b.value - a.value); // Sort by value descending

    return {
      situationData: transformAndSortData(situationCount),
      jobData: transformAndSortData(jobCount),
      outcomeData: transformAndSortData(outcomeCount),
    };
  }, [filteredData]);

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Jobs To Be Done characteristics</Typography>
      <Grid container spacing={3}>
        {/* Bar Chart for Situation */}
        <Grid item xs={12} md={4}>
          <Typography variant="h6">Situation ("When...")</Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={situationData} layout="vertical">
              <XAxis type="number" hide />
              <YAxis type="category" dataKey="name" width={150} />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="value" isAnimationActive={false}>
                {situationData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
                {/* Add custom labels to each bar */}
                <LabelList
                  dataKey="value"
                  content={(props) => renderCustomLabel({ ...props, data: situationData })}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        {/* Bar Chart for Job */}
        <Grid item xs={12} md={4}>
          <Typography variant="h6">Job To Be Done ("I want...")</Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={jobData} layout="vertical">
              <XAxis type="number" hide />
              <YAxis type="category" dataKey="name" width={150} />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="value" isAnimationActive={false}>
                {jobData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
                {/* Add custom labels to each bar */}
                <LabelList
                  dataKey="value"
                  content={(props) => renderCustomLabel({ ...props, data: jobData })}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        {/* Bar Chart for Desired Outcome */}
        <Grid item xs={12} md={4}>
          <Typography variant="h6">Desired Outcome ("So I...")</Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={outcomeData} layout="vertical">
              <XAxis type="number" hide />
              <YAxis type="category" dataKey="name" width={150} />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="value" isAnimationActive={false}>
                {outcomeData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
                {/* Add custom labels to each bar */}
                <LabelList
                  dataKey="value"
                  content={(props) => renderCustomLabel({ ...props, data: outcomeData })}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

export default NeedsBarCharts;

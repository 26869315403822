import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Box, Typography, Paper, Avatar, List, ListItem, ListItemText, Grid, Select, MenuItem, Card, CardContent } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { DataAndFilterContext } from './context/DataAndFilterContext'; 
import { useParams } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import NavBar from './components/NavBar';
import FilterBar from './components/FilterBar';

const getMonthLabel = (date) => {
  const options = { month: 'short', year: '2-digit' };
  return date.toLocaleDateString('en-US', options);
};

const generateMonthRange = (startDate, endDate) => {
  const months = [];
  const current = new Date(startDate);

  while (current <= endDate) {
    months.push(getMonthLabel(current));
    current.setMonth(current.getMonth() + 1);
  }

  return months;
};

function PersonaProfile() {
  const { persona_id } = useParams();
  const [persona, setPersona] = useState(null);
  const [personaCount, setPersonaCount] = useState(0);
  const [dateRange, setDateRange] = useState(3); // Default to 3 months
  const { filteredData, updateFilters } = useContext(DataAndFilterContext);

  useEffect(() => {
    const fetchPersonaDetails = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`/api/persona_detail_by_id/${persona_id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.ok) {
          const data = await response.json();
          setPersona(data);

          // Set the persona filter in the context to the specific persona and disable it
          updateFilters({ selectedPersona: [data.name] });
        } else {
          console.error('Failed to fetch persona details:', response.status);
        }
      } catch (error) {
        console.error('Error fetching persona details:', error);
      }
    };

    fetchPersonaDetails();
  }, [persona_id, updateFilters]);

  useEffect(() => {
    if (persona) {
      const countCustomers = () => {
        const today = new Date();
        const ninetyDaysAgo = new Date(today.setDate(today.getDate() - 90));

        const count = filteredData.filter((file) => {
          const fileDate = new Date(file.conversation_date);
          return (
            file.segmentation_data?.persona === persona.name && // Match persona name
            fileDate >= ninetyDaysAgo
          );
        }).length;

        setPersonaCount(count);
      };

      countCustomers();
    }
  }, [filteredData, persona]);

  const growthData = useMemo(() => {
    if (!persona) return [];

    const today = new Date();
    const startDate = new Date(today.setMonth(today.getMonth() - dateRange));

    const allMonths = generateMonthRange(startDate, new Date());
    const initialData = allMonths.map((month) => ({ month, count: 0 }));

    filteredData.forEach((file) => {
      const fileDate = new Date(file.conversation_date);
      if (file.segmentation_data?.persona === persona.name && fileDate >= startDate) {
        const monthLabel = getMonthLabel(fileDate);
        const monthIndex = initialData.findIndex((data) => data.month === monthLabel);

        if (monthIndex !== -1) {
          initialData[monthIndex].count += 1;
        }
      }
    });

    return initialData;
  }, [filteredData, persona, dateRange]);

  if (!persona) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
        <FilterBar personaName={persona.name} /> 
        <Box sx={{ p: 3 }}>
            <Paper elevation={3} sx={{ mb: 4, p: 3, display: 'flex', alignItems: 'center' }}>
                <Avatar
                variant="square"
                src={`/static/persona_photos/${persona.image_filename}`}
                alt={persona.name}
                sx={{ width: 200, height: 200, mr: 3, borderRadius: 2 }}
                />
                <Box>
                <Typography variant="h3">{persona.name}</Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                    {persona.description}
                </Typography>
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                    Total customers in the past 90 days: {personaCount}
                </Typography>
                </Box>
            </Paper>

            {/* Key Characteristics */}
            <Box sx={{ mb: 4 }}>
                <Typography variant="h4" gutterBottom>Key Characteristics</Typography>
                <Grid container spacing={2}>
                {[
                    { label: "Jobs to be done", items: persona.jobs_to_be_done },
                    { label: "Triggers", items: persona.triggers },
                    { label: "Demographics", items: persona.demographics },
                    { label: "Behaviors", items: persona.behaviors },
                    { label: "Decision-Making Factors", items: persona.decision_making_factors },
                    { label: "Challenges", items: persona.challenges },
                ].map((characteristic, index) => (
                    <Grid item xs={12} md={6} key={index}>
                    <Card>
                        <CardContent>
                        <Typography variant="h6">{characteristic.label}</Typography>
                        <List>
                            {characteristic.items.map((item, idx) => (
                            <ListItem key={idx} sx={{ py: 0 }}>
                                <ListItemText primary={item} />
                            </ListItem>
                            ))}
                        </List>
                        </CardContent>
                    </Card>
                    </Grid>
                ))}
                </Grid>
            </Box>

            <Box>
                <Typography variant="h4" gutterBottom>Growth over time</Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                Number of customer conversations with this persona over time
                </Typography>
                <Select
                value={dateRange}
                onChange={(e) => setDateRange(e.target.value)}
                displayEmpty
                sx={{ mb: 3 }}
                >
                <MenuItem value={3}>3 months</MenuItem>
                <MenuItem value={6}>6 months</MenuItem>
                <MenuItem value={12}>12 months</MenuItem>
                <MenuItem value={24}>24 months</MenuItem>
                </Select>
                <ResponsiveContainer width="100%" height={300}>
                <BarChart data={growthData}>
                    <XAxis dataKey="month" />
                    <YAxis allowDecimals={false} />
                    <Tooltip />
                    <Bar dataKey="count" fill="#3f51b5" />
                </BarChart>
                </ResponsiveContainer>
            </Box>
        </Box>
    </ThemeProvider>
  );
}

export default PersonaProfile;

import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import NavBar from './components/NavBar';

// Company Background Form Component
const CompanyBackgroundForm = () => {
    const [websites, setWebsites] = useState('');
    const [analysisResult, setAnalysisResult] = useState('');
    const [loading, setLoading] = useState(false);
    const [hasBackgroundInfo, setHasBackgroundInfo] = useState(false); // To track if the company has background info
    const [showAnalysisTextarea, setShowAnalysisTextarea] = useState(false); // To show/hide the second textarea

    // Function to fetch background info on page load
    const fetchBackgroundInfo = async () => {
        const token = localStorage.getItem('access_token');
        try {
            const response = await fetch('/get_background_info', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (data.content) {
                setAnalysisResult(data.content);
                setShowAnalysisTextarea(true); // Show the second textarea if background info exists
                setHasBackgroundInfo(true);
            }
        } catch (error) {
            console.error('Error fetching background info:', error);
        }
    };

    // Run on page load to fetch the company background
    useEffect(() => {
        fetchBackgroundInfo();
    }, []);

    // Function to analyze company data
    const handleAnalyze = async () => {
        setLoading(true);
        const token = localStorage.getItem('access_token');
        try {
            const response = await fetch('/analyze', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ websites }),
            });
            const data = await response.json();
            if (data.analysis) {
                setAnalysisResult(data.analysis);
                setShowAnalysisTextarea(true); // Unhide the second textarea after analysis if there's a result
            }
        } catch (error) {
            console.error('Error during analysis:', error);
            setAnalysisResult('Error during analysis.');
        }
        setLoading(false);
    };

    // Function to save or update company background info
    const handleSubmit = async () => {
        setLoading(true);
        const token = localStorage.getItem('access_token');
        try {
            const response = await fetch('/save_background', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ content: analysisResult }),
            });
            if (response.ok) {
                alert('Company background updated successfully');
            } else {
                alert('Error updating company background');
            }
        } catch (error) {
            console.error('Error submitting:', error);
        }
        setLoading(false);
    };

    return (
        <div>
            <h2>Your company background</h2>

            {/* Input for company websites */}
            <textarea
                placeholder="Provide link(s) to your company website(s)"
                value={websites}
                onChange={(e) => setWebsites(e.target.value)}
                rows="2"
                style={{ width: '100%' }}
            />

            {/* Button to analyze company data */}
            <button onClick={handleAnalyze} disabled={loading}>
                {loading ? 'Analyzing...' : 'Generate company information'}
            </button>

            {/* Conditionally show the analysis result textarea */}
            {showAnalysisTextarea && (
                <textarea
                    value={analysisResult}
                    onChange={(e) => setAnalysisResult(e.target.value)} // Editable textarea
                    rows="4"
                    style={{ width: '100%', marginTop: '10px' }}
                />
            )}

            {/* Button to save/update company background info */}
            <button onClick={handleSubmit} disabled={loading}>
                {loading ? 'Updating...' : 'Update company information'}
            </button>
        </div>
    );
};

// File Upload Component
const FileUpload = () => {
    const [selectedFiles, setSelectedFiles] = useState(null);

    const handleFileChange = (e) => {
        setSelectedFiles(e.target.files);
    };

    const handleUpload = async () => {
        const formData = new FormData();
        const token = localStorage.getItem('access_token');
        if (selectedFiles) {
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append('files', selectedFiles[i]);
            }
            try {
                const response = await fetch('/files', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}` // Add JWT token here
                    },
                    body: formData
                });
                if (response.ok) {
                    alert('Files uploaded successfully, starting processing...');
                } else {
                    alert('Error uploading files');
                }
            } catch (error) {
                console.error('Error during file upload:', error);
            }
        }
    };

    return (
        <div>
            <h2>Upload customer conversations</h2>
            <input type="file" multiple onChange={handleFileChange} />
            <button onClick={handleUpload} disabled={!selectedFiles}>
                Upload
            </button>
        </div>
    );
};

// Files Table Component
const FilesTable = () => {
    const [files, setFiles] = useState([]);

    const fetchFiles = async () => {
        const token = localStorage.getItem('access_token');
        try {
            const response = await fetch('/files/statuses', {
                headers: {
                    'Authorization': `Bearer ${token}` // Add JWT token here
                }
            });
            const data = await response.json();
            setFiles(data.files);
        } catch (error) {
            console.error('Error fetching file statuses:', error);
        }
    };

    useEffect(() => {
        fetchFiles();
        const interval = setInterval(fetchFiles, 2000); // Polling every 2 seconds
        return () => clearInterval(interval);
    }, []);

    const handleDelete = async (filename) => {
        const token = localStorage.getItem('access_token');
        if (window.confirm(`Are you sure you want to delete ${filename}?`)) {
            try {
                const response = await fetch(`/delete_file/${encodeURIComponent(filename)}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}` // Add JWT token here
                    }
                });
                if (response.ok) {
                    alert(`File "${filename}" deleted successfully.`);
                    setFiles(files.filter(file => file.filename !== filename));
                } else {
                    alert(`Failed to delete "${filename}".`);
                }
            } catch (error) {
                console.error('Error deleting file:', error);
            }
        }
    };

    return (
        <div>
            <h4>Uploaded files</h4>
            <table>
                <thead>
                    <tr>
                        <th>Filename</th>
                        <th>Delete</th>
                        <th>Upload Date</th>
                        <th>Overall Status</th>
                        <th>Step A: Prepare File</th>
                        <th>Step B: Extract Metadata</th>
                        <th>Step C: Extract Buying Behaviors</th>
                        <th>Step D: Extract Jobs To Be Done</th>
                        <th>Step E: Extract Insights</th>
                        <th>Step F: Match Insights to Jobs</th>
                        <th>Step G: Save Customer Story</th>
                        <th>Step H: Update Persona</th>
                        <th>Step I: Identify Patterns</th>
                    </tr>
                </thead>
                <tbody>
                    {files.map(file => (
                        <tr key={file.filename}>
                            <td>{file.filename}</td>
                            <td>
                                <button onClick={() => handleDelete(file.filename)}>Delete</button>
                            </td>
                            <td>{file.upload_date}</td>
                            <td>{file.overall_status}</td>
                            <td>{file.status['Step A: Prepare File']}</td>
                            <td>{file.status['Step B: Extract Metadata']}</td>
                            <td>{file.status['Step C: Extract Buying Behaviors']}</td>
                            <td>{file.status['Step D: Extract Jobs To Be Done']}</td>
                            <td>{file.status['Step E: Extract Insights']}</td>
                            <td>{file.status['Step F: Match Insights to Jobs']}</td>
                            <td>{file.status['Step G: Save Customer Story']}</td>
                            <td>{file.status['Step H: Update Persona']}</td>
                            <td>{file.status['Step I: Identify Patterns']}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

// Recategorize Button Component
const RecategorizeButton = () => {
    const [processing, setProcessing] = useState(false);

    const handleRecategorize = async () => {
        const token = localStorage.getItem('access_token');
        if (window.confirm('This will re-categorize all your customer conversations. Proceed?')) {
            setProcessing(true);
            try {
                const response = await fetch('/recategorize', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}` // Add JWT token here
                    }
                });
                const data = await response.json();
                alert(data.message);
            } catch (error) {
                console.error('Error recategorizing:', error);
                alert('An error occurred during recategorization.');
            }
            setProcessing(false);
        }
    };

    return (
        <div>
            <button onClick={handleRecategorize} disabled={processing}>
                {processing ? 'Processing...' : 'Re-categorize'}
            </button>
        </div>
    );
};

// Main ManageData Component to include all components
const ManageData = () => {
    return (
        <ThemeProvider theme={theme}>
            <NavBar />
            <Box sx={{ padding: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Manage Data
                </Typography>
 
                <div className="container">
                    <CompanyBackgroundForm />
                    <hr style={{ marginTop: '20px', marginBottom: '20px' }} />
                    
                    <FileUpload />
                    <br />
                    
                    <FilesTable />
                    <hr style={{ marginTop: '20px', marginBottom: '20px' }} />
                    
                    <RecategorizeButton />
                </div>
            </Box>
        </ThemeProvider>
    );
};

export default ManageData;

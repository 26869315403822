import React, { useContext } from 'react';
import {
  Box, Typography
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import NavBar from './components/NavBar';
import FilterBar from './components/FilterBar';
import theme from './theme';
import { DataAndFilterContext } from './context/DataAndFilterContext';
import InsightsTrends from './components/data_visualizations/InsightsTrends';
import InsightsTrendsCharts from './components/data_visualizations/InsightsTrendsCharts';
import InsightsHeatmaps from './components/data_visualizations/InsightsHeatmaps';
import InsightsTable from './components/data_visualizations/InsightsTable';

function UsageInsights() {
  const { filteredData, loading } = useContext(DataAndFilterContext);  // Get filtered conversations from context

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Usage Insights
        </Typography>
        <FilterBar />
        <InsightsTrends />
        <br /><br />
        <InsightsTrendsCharts />
        <br /><br />
        <InsightsHeatmaps />
        <br /><br />
        <InsightsTable />
        <br /><br />
      </Box>
    </ThemeProvider>
  );
  
}

export default UsageInsights;

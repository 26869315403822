import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const navItems = [
  { label: 'Usage Insights', path: '/usage-insights' },
  { label: 'Competitor Comparisons', path: '/buying-behaviors' },
  { label: 'Personas', path: '/personas' },
  { label: 'Customer Needs', path: '/customer-needs' },
  { label: 'All Conversations', path: '/conversations' },
];

const buyingBehaviorSubItems = [
  { label: 'Chosen Solutions', path: '/chosen-solutions' },
  { label: 'Previous Solutions', path: '/previous-solutions' },
  { label: 'Considered Solutions', path: '/considered-solutions' },
  { label: 'Triggers for Seeking New Solution', path: '/triggers' },
];

const NavBar = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [buyingAnchorEl, setBuyingAnchorEl] = useState(null);

  const handleAvatarMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAvatarMenuClose = () => {
    setAnchorEl(null);
  };

  const handleManageDataClick = () => {
    handleAvatarMenuClose();
    navigate('/managedata');
  };

  const handleIntegrationsClick = () => {
    handleAvatarMenuClose();
    navigate('/integrations');
  };

  const handleManageProjectsClick = () => {
    handleAvatarMenuClose();
    navigate('/manage-projects');
  };

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    navigate('/login');
  };

  const handleBuyingBehaviorClick = (event) => {
    setBuyingAnchorEl(event.currentTarget);
  };

  const handleBuyingBehaviorClose = () => {
    setBuyingAnchorEl(null);
  };

  const handleSubItemClick = (path) => {
    handleBuyingBehaviorClose();
    navigate(path);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src="/CIAlogo.png" alt="Logo" style={{ flexGrow: 1, height: '50px', width: 'auto' }} />
        </div>
        {navItems.map((item) => (
          item.label === 'Competitor Comparisons' ? (
            <MenuItem key={item.label} onClick={handleBuyingBehaviorClick}>
              {item.label}
            </MenuItem>
          ) : (
            <MenuItem key={item.label} onClick={() => navigate(item.path)}>
              {item.label}
            </MenuItem>
          )
        ))}
        <Menu
          anchorEl={buyingAnchorEl}
          open={Boolean(buyingAnchorEl)}
          onClose={handleBuyingBehaviorClose}
        >
          {buyingBehaviorSubItems.map((subItem) => (
            <MenuItem key={subItem.label} onClick={() => handleSubItemClick(subItem.path)}>
              {subItem.label}
            </MenuItem>
          ))}
        </Menu>
        <IconButton onClick={handleAvatarMenuClick} sx={{ marginLeft: 'auto' }}>
          <Avatar />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleAvatarMenuClose}
        >
          <MenuItem onClick={handleAvatarMenuClose}>Profile</MenuItem>
          <MenuItem onClick={handleAvatarMenuClose}>Settings</MenuItem>
          <MenuItem onClick={handleIntegrationsClick}>Integrations</MenuItem>
          <MenuItem onClick={handleManageDataClick}>Manage Data</MenuItem>
          <MenuItem onClick={handleManageProjectsClick}>AI Interview Projects</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;

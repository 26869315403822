// ForgotPasswordPage.js
import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Alert } from '@mui/material';

function ForgotPasswordPage() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/api/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();

            if (response.ok) {
                setMessage(data.message);
                setError('');
            } else {
                setError(data.message);
                setMessage('');
            }
        } catch (err) {
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <Box sx={{ width: '100%', maxWidth: 400, mx: 'auto', mt: 5 }}>
            <Typography variant="h4" gutterBottom>Forgot Password</Typography>
            {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}
            {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <Button variant="contained" type="submit" fullWidth sx={{ mt: 2 }}>Submit</Button>
            </form>
            <Typography variant="body2" sx={{ mt: 2 }}>
                <a href="/login">Log In</a> | <a href="/signup">Sign Up</a>
            </Typography>
        </Box>
    );
}

export default ForgotPasswordPage;


/* OLD VERSION

import React, { useState } from 'react';

function ForgotPasswordPage() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');  // Separate error state

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/api/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();

            if (response.ok) {
                setMessage(data.message);  // Show success message
                setError('');  // Clear any previous error message
            } else {
                setError(data.message);  // Show error message if email not found
                setMessage('');  // Clear any previous success message
            }
        } catch (err) {
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <div>
            <h1>Forgot Password</h1>
            {message && <p style={{ color: 'green' }}>{message}</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <form onSubmit={handleSubmit}>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <button type="submit">Submit</button>
            </form>
            <p>
                <a href="/login">Log In</a> | <a href="/signup">Sign Up</a>
            </p>
        </div>
    );
}

export default ForgotPasswordPage;
*/
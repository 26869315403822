import React, { useContext } from 'react';
import {
  Box, Typography
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import NavBar from './components/NavBar';
import FilterBar from './components/FilterBar';
import theme from './theme';
import { DataAndFilterContext } from './context/DataAndFilterContext';
import NeedsImportanceVsSatisfaction from './components/data_visualizations/NeedsImportanceVsSatisfaction';
import NeedsImportanceAndSatisfactionOverTime from './components/data_visualizations/NeedsImportanceAndSatisfactionOverTime';
import NeedsCharacteristics from './components/data_visualizations/NeedsCharacteristics';
import NeedsSatisfactionWordClouds from './components/data_visualizations/NeedsSatisfactionWordClouds';
import NeedsSatisfactionAndImportanceDataTable from './components/data_visualizations/NeedsSatisfactionAndImportanceDataTable';

function CustomerNeedsPage() {
  const { filteredData, loading } = useContext(DataAndFilterContext);  // Get filtered conversations from context

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Customer Needs
        </Typography>
        <FilterBar />
        
        <NeedsImportanceVsSatisfaction />
        <br /><br />
        <NeedsImportanceAndSatisfactionOverTime />
        <br /><br />
        <NeedsCharacteristics />
        <br /><br />
        <NeedsSatisfactionWordClouds />
        <br /><br />
        <NeedsSatisfactionAndImportanceDataTable />
        <br /><br />
      </Box>
    </ThemeProvider>
  );
  
}

export default CustomerNeedsPage;

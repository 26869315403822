import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

function PrivateRoute() {
    const token = localStorage.getItem('access_token');

    if (token) {
        // Decode the token and check its expiration
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds

        if (decodedToken.exp < currentTime) {
            // Token has expired, so remove it and redirect to login
            localStorage.removeItem('access_token');
            return <Navigate to="/login" />;
        }

        // Token is valid
        return <Outlet />;
    }

    // No token, redirect to login
    return <Navigate to="/login" />;
}

export default PrivateRoute;

import React, { useContext, useState, useMemo } from 'react';
import { Box, Typography, Grid, Checkbox, FormControlLabel } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { format, parse, eachMonthOfInterval, subMonths } from 'date-fns';
import { DataAndFilterContext } from '../../context/DataAndFilterContext';
import ConsideredSolutionsTable from './ConsideredSolutionsTable';
import ReasonsNotChosenHeatmap from './ReasonsNotChosenHeatmap';

const COLORS = ['#00C9A7', '#FF6B6B', '#1E90FF', '#FFD700', '#9B59B6'];

function ConsideredSolutions() {
  const { filteredData, filters } = useContext(DataAndFilterContext);
  const [visibleCategories, setVisibleCategories] = useState([]);

  // Compute data for the pie chart
  const pieChartData = useMemo(() => {
    const categoryCount = {};
    filteredData.forEach((item) => {
      const category = item.considered_solutions?.[0]?.considered_solution_category;
      if (category) {
        categoryCount[category] = (categoryCount[category] || 0) + 1;
      }
    });

    return Object.keys(categoryCount).map((category) => ({
      name: category,
      value: categoryCount[category],
    }));
  }, [filteredData]);

  // Generate months range based on date filters
  const monthsInRange = useMemo(() => {
    const defaultEndDate = new Date();
    let startDate;
    let endDate = defaultEndDate;

    if (filters.dateRange === '6 months') {
      startDate = subMonths(endDate, 6);
    } else if (filters.dateRange === '3 months') {
      startDate = subMonths(endDate, 3);
    } else if (filters.dateRange === 'Custom' && filters.customDateRange.from && filters.customDateRange.to) {
      startDate = new Date(filters.customDateRange.from);
      endDate = new Date(filters.customDateRange.to);
    } else {
        // For "All Time", find the earliest conversation date in the data
        const earliestDate = filteredData.reduce((earliest, item) => {
          const conversationDate = new Date(item.conversation_date);
          return conversationDate < earliest ? conversationDate : earliest;
        }, new Date());
        startDate = earliestDate;
      }

    return eachMonthOfInterval({ start: startDate, end: endDate }).map((month) =>
      format(month, "MMM ''yy")
    );
  }, [filters]);

  // Compute data for the line chart
  const lineChartData = useMemo(() => {
    const monthlyData = {};
    filteredData.forEach((item) => {
      const category = item.considered_solutions?.[0]?.considered_solution_category;
      const conversationMonth = format(parse(item.conversation_date, 'MM-dd-yyyy', new Date()), "MMM ''yy");

      if (category) {
        if (!monthlyData[conversationMonth]) {
          monthlyData[conversationMonth] = {};
        }

        if (!monthlyData[conversationMonth][category]) {
          monthlyData[conversationMonth][category] = 0;
        }

        monthlyData[conversationMonth][category] += 1;
      }
    });

    return monthsInRange.map((month) => {
      const monthData = { month };
      pieChartData.forEach((category) => {
        monthData[category.name] = monthlyData[month]?.[category.name] || 0;
      });
      return monthData;
    });
  }, [filteredData, monthsInRange, pieChartData]);

  // Default visibility: show all categories initially
  useMemo(() => {
    const allCategories = pieChartData.map((d) => d.name);
    setVisibleCategories(allCategories);
  }, [pieChartData]);

  // Handle toggling visibility of categories
  const handleCategoryToggle = (category) => {
    if (visibleCategories.includes(category)) {
      setVisibleCategories(visibleCategories.filter((c) => c !== category));
    } else {
      setVisibleCategories([...visibleCategories, category]);
    }
  };

  return (
    <Box>
      <Grid container spacing={4} sx={{ mb: 4 }}>
        {/* Pie Chart */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Considered Solutions</Typography>
          <Typography variant="body2" gutterBottom>
            These are the solutions that customers considered using or used alongside their chosen solution.
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={pieChartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                label={({ name }) => name}
              >
                {pieChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </Grid>

        {/* Line Chart */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Monthly Changes in Considered Solutions</Typography>
          <Typography variant="body2" gutterBottom>
            Number of conversations mentioning the considered solutions
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={lineChartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              {pieChartData.map((category, index) => (
                visibleCategories.includes(category.name) && (
                  <Line
                    key={category.name}
                    type="monotone"
                    dataKey={category.name}
                    stroke={COLORS[index % COLORS.length]}
                    activeDot={{ r: 8 }}
                  />
                )
              ))}
            </LineChart>
          </ResponsiveContainer>

          {/* Checkboxes */}
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              maxWidth: '100%',
              margin: 'auto',
              mt: 2,
            }}
          >
            {pieChartData.map((category, index) => (
              <FormControlLabel
                key={category.name}
                control={
                  <Checkbox
                    checked={visibleCategories.includes(category.name)}
                    onChange={() => handleCategoryToggle(category.name)}
                    sx={{
                      color: COLORS[index % COLORS.length],
                      '&.Mui-checked': {
                        color: COLORS[index % COLORS.length],
                      },
                    }}
                  />
                }
                label={category.name}
                sx={{
                  color: COLORS[index % COLORS.length],
                  marginRight: 2,
                  whiteSpace: 'nowrap', // Prevents text wrapping
                }}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
      <ReasonsNotChosenHeatmap filteredData={filteredData} />
      <ConsideredSolutionsTable />
    </Box>
  );
}

export default ConsideredSolutions;

const chartColors = [
    '#16A085', // Modern Green
    '#FF6B6B', // Vibrant Coral
    '#9B59B6', // Bold Amethyst
    '#F39C12', // Bold Amber
    '#00C9A7', // Futuristic Mint
    '#FF9F43', // Vivid Orange
    '#8E44AD', // Deep Purple
    '#FFD700', // Futuristic Gold
    '#1E90FF', // Bright Azure
    '#2ECC71', // Lime Green
  ];
  
  export default chartColors;
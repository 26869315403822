import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Button
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme';
import NavBar from '../components/NavBar';
import { useNavigate } from 'react-router-dom';
import { Switch, CircularProgress, Snackbar } from '@mui/material';

const ManageProjects = () => {
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      const token = localStorage.getItem('access_token');
      if (!token) {
        alert('Authentication token is missing. Please log in.');
        navigate('/login');
        return;
      }

      try {
        const response = await fetch('/api/get-projects', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch projects');
        }

        const data = await response.json();
        setProjects(data.projects);
      } catch (error) {
        console.error('Error fetching projects:', error);
        alert('An error occurred while fetching projects.');
      }
    };

    fetchProjects();
  }, [navigate]);
  
  const handleCreateProjectRedirect = () => {
    navigate('/create-project');
  };

  const [isProcessing, setIsProcessing] = useState(null); // Track processing state for each project

  const handleStatusToggle = async (projectId, currentStatus) => {
    setIsProcessing(projectId);
    const newStatus = currentStatus === 'On' ? 'Off' : 'On';
    const token = localStorage.getItem('access_token');

    try {
      const response = await fetch('/api/update-project-status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ project_id: projectId, new_status: newStatus }),
      });

      if (!response.ok) {
        throw new Error('Failed to update status');
      }

      // Update project state after successful response
      setProjects((prevProjects) =>
        prevProjects.map((project) =>
          project.project_id === projectId ? { ...project, status: newStatus } : project
        )
      );
    } catch (error) {
      console.error('Error updating project status:', error);
      alert('An error occurred while updating the project status.');
    } finally {
      setIsProcessing(null);
    }
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link);
    setSnackbarOpen(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Manage Projects
        </Typography>
        <Button variant="contained" color="primary" onClick={handleCreateProjectRedirect}>
          Create New Project
        </Button>

        <TableContainer component={Paper} sx={{ marginTop: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Project Name</TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell>Interviews Completed</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Participant Link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.length > 0 ? (
                projects.map((project, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Button variant="text" onClick={() => navigate(`/project-details/${project.project_id}`)}>
                        {project.project_name}
                      </Button>
                    </TableCell>
                    <TableCell>{project.created_date}</TableCell>
                    <TableCell>{project.interviews_complete}</TableCell>
                    <TableCell>
                      <Switch
                        checked={project.status === 'On'}
                        onChange={() => handleStatusToggle(project.project_id, project.status)}
                        disabled={isProcessing === project.project_id}
                      />
                      {isProcessing === project.project_id && <CircularProgress size={24} />}
                    </TableCell>
                    <TableCell>
                      <Button variant="outlined" onClick={() => handleCopyLink(project.participant_link)}>
                        Copy Link
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No projects available. Create one to get started.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message="Link copied!"
        />
      </Box>
    </ThemeProvider>
  );
};

export default ManageProjects;

import React, { useContext, useState, useEffect } from 'react';
import {
  Box, Typography, TextField, IconButton, Collapse, Select, MenuItem, Chip,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { DataAndFilterContext } from '../context/DataAndFilterContext';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function FilterBar({ personaName }) {
  const { filterOptions, filters, updateFilters, clearFilters, loading } = useContext(DataAndFilterContext);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (personaName) {
      // If personaName is provided, set it as the filter and disable changes (for the persona profile page)
      updateFilters({ selectedPersona: [personaName] });
    }
  }, [personaName, updateFilters]);

  useEffect(() => {
    if (!filters.dateRange) {
      // Set default date range only if it hasn't been set before
      updateFilters({ dateRange: '3 months' });
    }
  }, []);  // Empty dependency array ensures this only runs on the initial mount  

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setCollapsed(false);
      } else {
        setCollapsed(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleDateRangeChange = (event) => {
    updateFilters({
      dateRange: event.target.value,
      customDateRange: event.target.value !== 'Custom' ? { from: null, to: null } : filters.customDateRange,
    });
  };

  const handleCustomDateChange = (key, date) => {
    updateFilters({
      customDateRange: {
        ...filters.customDateRange,
        [key]: date,
      },
    });
  };

  const handleFilterChange = (key, values) => {
    if (key === 'selectedPersona' && personaName) {
      return; // Prevent changes if personaName is set (persona profile page only)
    }
    updateFilters({ [key]: values });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const getActiveFilters = () => {
    const activeFilters = [];
    if (filters.selectedSource && filters.selectedSource.length > 0) {
      activeFilters.push(...filters.selectedSource);
    }
    if (filters.selectedPersona && filters.selectedPersona.length > 0) {
      activeFilters.push(...filters.selectedPersona);
    }
    if (filters.selectedJobToBeDone && filters.selectedJobToBeDone.length > 0) {
      activeFilters.push(...filters.selectedJobToBeDone);
    }
    if (filters.selectedDesiredOutcome && filters.selectedDesiredOutcome.length > 0) {
      activeFilters.push(...filters.selectedDesiredOutcome);
    }
    if (filters.selectedCustomTags && filters.selectedCustomTags.length > 0) {
      activeFilters.push(...filters.selectedCustomTags);
    }
    if (filters.dateRange && filters.dateRange !== 'All time') {
      activeFilters.push(filters.dateRange);
    }
    return activeFilters;
  };

  const activeFilters = getActiveFilters();

  if (loading) {
    return <Typography>Loading filters...</Typography>;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          backgroundColor: 'white',
          borderBottom: '1px solid #ccc',
          padding: '10px 20px',
          mb: 3,
        }}
      >
        {collapsed && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="body2">Active filters:</Typography>
              {activeFilters.length > 0 ? (
                activeFilters.map((filter, index) => (
                  <Chip key={index} label={filter} size="small" />
                ))
              ) : (
                <Typography variant="body2">None</Typography>
              )}
            </Box>

            <IconButton onClick={scrollToTop}>
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        )}

        <Collapse in={!collapsed}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" sx={{ flex: '1 0 100%' }}>
              Filters
            </Typography>

            {/* Conversation Source Filter */}
            <Box sx={{ minWidth: 250 }}>
              <Typography variant="body2">Conversation Source:</Typography>
              <Autocomplete
                multiple
                options={filterOptions.conversation_sources}
                value={filters.selectedSource || []}
                onChange={(event, newValue) => handleFilterChange('selectedSource', newValue)}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Select Sources" placeholder="Select sources" />
                )}
              />
            </Box>

            {/* Persona Filter */}
            <Box sx={{ minWidth: 250 }}>
              <Typography variant="body2">Persona:</Typography>
              <Autocomplete
                multiple
                options={filterOptions.personas}
                value={filters.selectedPersona || []}
                onChange={(event, newValue) => handleFilterChange('selectedPersona', newValue)}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Personas"
                    placeholder="Select personas"
                    disabled={Boolean(personaName)} // Disable input if personaName is set
                  />
                )}
              />
            </Box>

            {/* Needs (Jobs To Be Done) Filter */}
            <Box sx={{ minWidth: 250 }}>
              <Typography variant="body2">Needs (Jobs To Be Done):</Typography>
              <Autocomplete
                multiple
                options={filterOptions.jobsToBeDone}
                value={filters.selectedJobToBeDone || []}
                onChange={(event, newValue) => handleFilterChange('selectedJobToBeDone', newValue)}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Select Needs" placeholder="Select needs" />
                )}
              />
            </Box>

            {/* Desired Outcomes Filter */}
            <Box sx={{ minWidth: 250 }}>
              <Typography variant="body2">Desired Outcomes:</Typography>
              <Autocomplete
                multiple
                options={filterOptions.desiredOutcomes}
                value={filters.selectedDesiredOutcome || []}
                onChange={(event, newValue) => handleFilterChange('selectedDesiredOutcome', newValue)}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Select Outcomes" placeholder="Select outcomes" />
                )}
              />
            </Box>

            {/* Solution Choice Filter */}
            <Box sx={{ minWidth: 250 }}>
              <Typography variant="body2">Solution Choice:</Typography>
              <Autocomplete
                multiple
                options={filterOptions.solutionChoices}
                value={filters.selectedSolutionChoice || []}
                onChange={(event, newValue) => handleFilterChange('selectedSolutionChoice', newValue)}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Select Solutions" placeholder="Select solutions" />
                )}
              />
            </Box>

            {/* Custom Tags Filter */}
            <Box sx={{ minWidth: 250 }}>
              <Typography variant="body2">Custom Tags:</Typography>
              <Autocomplete
                multiple
                options={filterOptions.customTags || []}
                value={filters.selectedCustomTags || []}
                onChange={(event, newValue) => handleFilterChange('selectedCustomTags', newValue)}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Select Tags" placeholder="Select tags" />
                )}
              />
            </Box>

            {/* Date Range Filter */}
            <Box sx={{ minWidth: 150 }}>
              <Typography variant="body2">Conversation Date:</Typography>
              <Select
                value={filters.dateRange}
                onChange={handleDateRangeChange}
                fullWidth
              >
                {['All time', '6 months', '3 months', 'Custom'].map((range) => (
                  <MenuItem key={range} value={range}>
                    {range}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            {/* Custom Date Pickers */}
            {filters.dateRange === 'Custom' && (
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <DatePicker
                  label="From"
                  value={filters.customDateRange.from}
                  onChange={(date) => handleCustomDateChange('from', date)}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
                <DatePicker
                  label="To"
                  value={filters.customDateRange.to}
                  onChange={(date) => handleCustomDateChange('to', date)}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
              </Box>
            )}

            <Box>
              <IconButton onClick={() => clearFilters()} size="small">
                <ClearIcon />
              </IconButton>
            </Box>

            <IconButton onClick={() => setCollapsed(true)}>
              <ExpandLessIcon />
            </IconButton>
          </Box>
        </Collapse>
      </Box>
    </LocalizationProvider>
  );
}

export default FilterBar;

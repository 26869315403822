import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box, Typography, Button, TextField, Alert, Snackbar, CircularProgress, Stack
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import NavBar from './components/NavBar';
import theme from './theme';

function EditIntegration() {
  const { integrationName } = useParams();
  const [deactivationStatus, setDeactivationStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDeactivate = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch(`/api/deactivate-integration`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ integrationName }),
      });

      if (response.ok) {
        setDeactivationStatus('Integration deactivated successfully!');
      } else {
        setDeactivationStatus('Failed to deactivate the integration.');
      }
    } catch (err) {
      setDeactivationStatus('An error occurred while deactivating the integration.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Edit Settings for {decodeURIComponent(integrationName)}
        </Typography>
        <TextField
          label="New Setting"
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2 }}
        />
        <Stack direction="row" spacing={2}>
          <Button variant="contained">Save Changes</Button>
          <Button
            variant="outlined"
            color="error"
            onClick={handleDeactivate}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Deactivate Integration'}
          </Button>
        </Stack>

        {deactivationStatus && (
          <Alert severity={deactivationStatus.includes('successfully') ? 'success' : 'error'} sx={{ marginTop: 2 }}>
            {deactivationStatus}
          </Alert>
        )}
        <Snackbar
          open={deactivationStatus && deactivationStatus.includes('successfully')}
          autoHideDuration={6000}
          message="Integration deactivated successfully!"
          onClose={() => setDeactivationStatus(null)}
        />
      </Box>
    </ThemeProvider>
  );
}

export default EditIntegration;

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: { main: '#1f2937' }, // Dark Gray
    secondary: { main: '#00bcd4' }, // Cyan
    background: { default: '#f3f4f6' }, // Light Gray
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h6: { fontWeight: 600 },
  },
});

export default theme;

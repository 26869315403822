import React, { useContext, useState } from 'react';
import { Box, Typography, Grid, Modal, Paper, Link, ButtonGroup, Button } from '@mui/material';
import { DataAndFilterContext } from '../../context/DataAndFilterContext';
import CustomerModal from '../CustomerModal';

const calculateAverageAndCount = (data, rowField, colField, rowValue, colValue) => {
  if (!Array.isArray(data)) return { average: null, count: 0, insights: [] };

  const matchingItems = data.flatMap((item) => {
    const jobInsights = (item.jobs_to_be_done || []).flatMap((job) =>
      (job.insights || []).map((insight) => ({
        ...insight,
        customer_name: item.customer_name,
        customer_data: item,
      })).filter(
        (insight) => insight[rowField] === rowValue && insight[colField] === colValue
      )
    );

    const miscInsights = (item.miscellaneous_insights || []).map((insight) => ({
      ...insight,
      customer_name: item.customer_name,
      customer_data: item,
    })).filter(
      (insight) => insight[rowField] === rowValue && insight[colField] === colValue
    );

    return [...jobInsights, ...miscInsights];
  });

  if (matchingItems.length === 0) return { average: null, count: 0, insights: [] };

  const totalSentiment = matchingItems.reduce(
    (acc, insight) => acc + insight.insight_sentiment_score,
    0
  );

  const average = totalSentiment / matchingItems.length;
  return { average, count: matchingItems.length, insights: matchingItems };
};

const getCellColor = (value) => {
  if (value === null) return '#D3D3D3';

  if (value < 0) {
    const intensity = Math.min(Math.abs(value) / 5, 1);
    const red = 255;
    const green = 255 - Math.round(intensity * 255);
    return `rgb(${red}, ${green}, ${green})`;
  } else {
    const intensity = Math.min(value / 5, 1);
    const green = 255;
    const red = 255 - Math.round(intensity * 255);
    return `rgb(${red}, ${green}, ${red})`;
  }
};

const InsightsHeatmaps = () => {
  const { filteredData, loading } = useContext(DataAndFilterContext);
  const [openModal, setOpenModal] = useState(false);
  const [selectedInsights, setSelectedInsights] = useState([]);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedOption, setSelectedOption] = useState(0);

  const buttonOptions = [
    { label: 'Insight Type vs Area of Product', rowField: 'insight_product_area', colField: 'insight_type' },
    { label: 'Insight Type vs Insight Category', rowField: 'insight_category', colField: 'insight_type' },
  ];

  const { rowField, colField } = buttonOptions[selectedOption];

  const orderedInsightTypes = [
    'Phase 1: Awareness', 'Phase 2: Interest', 'Phase 3: Trial', 'Phase 4: Evaluation', 'Phase 5: Adoption',
    'Likes', 'Dislikes', 'Challenges', 'Costs', 'Wishes', 'Future Needs', 'Market Trends'
  ];

  const handleCellClick = (insights) => {
    if (insights.length > 0) {
      setSelectedInsights(insights);
      setOpenModal(true);
    }
  };

  const handleClose = () => setOpenModal(false);

  const handleCustomerClick = (customer) => {
    setSelectedCustomer(customer);
    setCustomerModalOpen(true);
  };

  const handleCustomerModalClose = () => setCustomerModalOpen(false);

  if (loading) {
    return (
      <Box>
        <Typography variant="h6" align="center">
          Average Sentiment of Insights
        </Typography>
        <Typography variant="body1" align="center">
          Loading data...
        </Typography>
      </Box>
    );
  }

  if (!Array.isArray(filteredData) || filteredData.length === 0) {
    return (
      <Box>
        <Typography variant="h6" align="center">
          Average Sentiment of Insights
        </Typography>
        <Typography variant="body1" align="center">
          No data available to display the heatmap.
        </Typography>
      </Box>
    );
  }

  let rowValues = [
    ...new Set(
      filteredData.flatMap((item) => {
        const jobValues = (item.jobs_to_be_done || []).flatMap((job) =>
          (job.insights || []).map((insight) => insight[rowField])
        );
        const miscValues = (item.miscellaneous_insights || []).map(
          (insight) => insight[rowField]
        );
        return [...jobValues, ...miscValues];
      })
    ),
  ];

  if (rowField === 'insight_type') {
    rowValues = [
      ...orderedInsightTypes.filter((type) => rowValues.includes(type)),
      ...rowValues.filter((type) => !orderedInsightTypes.includes(type)),
    ];
  }

  const colValues = [
    ...new Set(
      filteredData.flatMap((item) => {
        const jobValues = (item.jobs_to_be_done || []).flatMap((job) =>
          (job.insights || []).map((insight) => insight[colField])
        );
        const miscValues = (item.miscellaneous_insights || []).map(
          (insight) => insight[colField]
        );
        return [...jobValues, ...miscValues];
      })
    ),
  ];

  return (
    <Box>
      <Typography variant="h6" align="center" gutterBottom>
        Average Sentiment of Insights
      </Typography>

      {/* Button Group for Selecting Heatmap Type */}
      <ButtonGroup
        sx={{ mb: 3 }}
        variant="contained"
        color="primary"
        fullWidth
        aria-label="heatmap options"
      >
        {buttonOptions.map((option, index) => (
          <Button
            key={index}
            onClick={() => setSelectedOption(index)}
            variant={selectedOption === index ? 'contained' : 'outlined'}
          >
            {option.label}
          </Button>
        ))}
      </ButtonGroup>

      {/* Header Row for Columns */}
      <Grid container>
        <Grid item xs={2}></Grid>
        {colValues.map((col, index) => (
          <Grid item xs key={`header-${index}`}>
            <Typography variant="subtitle2" align="center">
              {col}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {/* Data Rows */}
      {rowValues.map((row, rowIndex) => (
        <Grid container key={`row-${rowIndex}`}>
          <Grid item xs={2}>
            <Typography variant="subtitle2">{row}</Typography>
          </Grid>
          {colValues.map((col, colIndex) => {
            const { average, count, insights } = calculateAverageAndCount(
              filteredData,
              rowField,
              colField,
              row,
              col
            );
            const adjustedValue = average !== null ? average - 5 : null;
            const cellColor = getCellColor(adjustedValue);

            return (
              <Grid
                item
                xs
                key={`cell-${rowIndex}-${colIndex}`}
                style={{
                  backgroundColor: cellColor,
                  border: '1px solid #ccc',
                  padding: '8px',
                  textAlign: 'center',
                  cursor: insights.length > 0 ? 'pointer' : 'default',
                }}
                onClick={() => handleCellClick(insights)}
              >
                {average !== null ? (
                  <>
                    <Typography variant="body2">
                      {adjustedValue.toFixed(1)}
                    </Typography>
                    <Typography variant="caption" style={{ fontSize: '0.75rem' }}>
                      ({count} insights)
                    </Typography>
                  </>
                ) : (
                  'n/a'
                )}
              </Grid>
            );
          })}
        </Grid>
      ))}

      {/* Modal to show insights details */}
      <Modal open={openModal} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
        >
          <Typography variant="h6">Insight Details</Typography>
          {selectedInsights.map((insight, index) => (
            <Paper key={index} sx={{ p: 2, mb: 2 }}>
              <Typography variant="body1" fontWeight="bold" gutterBottom>
                "{insight.insight_quote}"
              </Typography>
              <Link
                href="#"
                variant="body2"
                underline="hover"
                sx={{ color: 'secondary.main', display: 'block', mb: 1 }}
              >
                Timestamps: {insight.insight_timestamps}
              </Link>

              <Box sx={{ mb: 2 }}>
                <Typography variant="body2">Summary: {insight.insight_summary}</Typography>
              </Box>

              {/* Tags Group */}
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2">Type: {insight.insight_type}</Typography>
                <Typography variant="body2">Product Area: {insight.insight_product_area}</Typography>
                <Typography variant="body2">Category: {insight.insight_category}</Typography>
              </Box>

              {/* Sentiment Group */}
              <Box>
                <Typography variant="body2">Sentiment Overall: {insight.insight_sentiment_overall}</Typography>
                <Typography variant="body2">Sentiment Expressed: {insight.insight_sentiment_expressed}</Typography>
                <Typography variant="body2">
                  Sentiment Score: {insight.insight_sentiment_score - 5 > 0 ? '+' : ''}
                  {insight.insight_sentiment_score - 5}
                </Typography>
                <Typography variant="body2">Sentiment Justification: {insight.insight_sentiment_justification}</Typography>
              </Box>

              <Typography
                variant="body2"
                sx={{ cursor: 'pointer', color: 'primary.main', mt: 2 }}
                onClick={() => handleCustomerClick(insight.customer_data)}
              >
                Customer: {insight.customer_name}
              </Typography>
            </Paper>
          ))}
        </Box>
      </Modal>

      {/* Customer Modal */}
      <CustomerModal
        open={customerModalOpen}
        onClose={handleCustomerModalClose}
        customer={selectedCustomer}
      />
    </Box>
  );
};

export default InsightsHeatmaps;

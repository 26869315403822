// ResetPasswordPage.js
import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Alert } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

function ResetPasswordPage() {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`/api/reset-password/${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ password }),
            });
            const data = await response.json();

            if (response.ok) {
                setMessage('Password reset successful. Redirecting to login...');
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            } else {
                setMessage(data.error || 'An error occurred. Please try again.');
            }
        } catch (err) {
            setMessage('An error occurred. Please try again.');
        }
    };

    return (
        <Box sx={{ width: '100%', maxWidth: 400, mx: 'auto', mt: 5 }}>
            <Typography variant="h4" gutterBottom>Reset Password</Typography>
            {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}
            <form onSubmit={handleSubmit}>
                <TextField
                    label="New Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <Button variant="contained" type="submit" fullWidth sx={{ mt: 2 }}>Reset Password</Button>
            </form>
        </Box>
    );
}

export default ResetPasswordPage;


/* OLD VERSION
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

function ResetPasswordPage() {
    const { token } = useParams();  // Assuming the token is passed in the URL
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`/api/reset-password/${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ password }),
            });
            const data = await response.json();

            if (response.ok) {
                setMessage('Password reset successful. Redirecting to login...');
                setTimeout(() => {
                    navigate('/login');  // Redirect to login after a couple seconds
                }, 2000);  // Wait for 2 seconds before redirecting
            } else {
                setMessage(data.error || 'An error occurred. Please try again.');
            }
        } catch (err) {
            setMessage('An error occurred. Please try again.');
        }
    };

    return (
        <div>
            <h1>Reset Password</h1>
            {message && <p>{message}</p>}
            <form onSubmit={handleSubmit}>
                <input
                    type="password"
                    placeholder="New Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit">Reset Password</button>
            </form>
        </div>
    );
}

export default ResetPasswordPage;
*/
import React, { useContext, useState, useMemo } from 'react';
import { Box, Typography, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Legend } from 'recharts';
import { DataGrid } from '@mui/x-data-grid';
import { DataAndFilterContext } from '../../context/DataAndFilterContext';
import CustomerModal from '../CustomerModal';

// Define colors for the charts
const PIE_COLORS = ['#FF7F50', '#87CEEB', '#9370DB', '#FFD700', '#FF6347'];
const BAR_COLOR = '#8884d8'; // Different color for the bar chart

// Helper function to wrap text into lines
function wrapText(text, maxWidth) {
  const words = text.split(' ');
  const lines = [];
  let currentLine = words[0];

  for (let i = 1; i < words.length; i++) {
    if (currentLine.length + words[i].length + 1 <= maxWidth) {
      currentLine += ` ${words[i]}`;
    } else {
      lines.push(currentLine);
      currentLine = words[i];
    }
  }
  lines.push(currentLine);
  return lines;
}

// Custom X-axis Tick Component for Wrapping Labels
function CustomXAxisTick({ x, y, payload }) {
  const { value } = payload;
  const maxWidth = 15; // Set max width in terms of characters for each line
  const lines = wrapText(value, maxWidth);

  return (
    <text
      x={x}
      y={y}
      textAnchor="middle"
      style={{ fontSize: '14px', transform: 'translateY(10px)' }} // CSS transform to adjust position
    >
      {lines.map((line, index) => (
        <tspan x={x} dy={index === 0 ? 0 : 14} key={index}> {/* Line spacing */}
          {line}
        </tspan>
      ))}
    </text>
  );
}

function PreviousSolutions() {
  const { filteredData } = useContext(DataAndFilterContext);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [page, setPage] = useState(1);
  const [sortModel, setSortModel] = useState([{ field: 'conversation_date', sort: 'desc' }]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const ITEMS_PER_PAGE = 10;

  // Filter data to only include items where "previous_solution" is defined
  const filteredAndValidData = useMemo(() => {
    return filteredData.filter((item) => item.previous_solution);
  }, [filteredData]);

  // Compute data for the pie chart (Previous Solutions)
  const previousSolutionData = useMemo(() => {
    const categoryCount = {};
    filteredAndValidData.forEach((item) => {
      const category = item.previous_solution_category;
      if (category) {
        categoryCount[category] = (categoryCount[category] || 0) + 1;
      }
    });

    const totalFiles = filteredAndValidData.length;
    return Object.keys(categoryCount).map((category) => ({
      name: category,
      value: categoryCount[category],
      percentage: ((categoryCount[category] / totalFiles) * 100).toFixed(2),
    })).sort((a, b) => b.value - a.value);
  }, [filteredAndValidData]);

  // Compute data for the bar chart (Stickiness Level)
  const stickinessData = useMemo(() => {
    const categoryStickiness = {};
    const categoryCount = {};

    filteredAndValidData.forEach((item) => {
      const category = item.previous_solution_category;
      if (category && item.stickiness_level_of_previous_solution !== null) {
        categoryStickiness[category] = (categoryStickiness[category] || 0) + item.stickiness_level_of_previous_solution;
        categoryCount[category] = (categoryCount[category] || 0) + 1;
      }
    });

    return Object.keys(categoryStickiness).map((category) => ({
      name: category,
      averageStickiness: (categoryStickiness[category] / categoryCount[category]).toFixed(2),
    }));
  }, [filteredAndValidData]);

  // Data for the table
  const filteredTableData = useMemo(() => {
    return filteredAndValidData
      .filter((item) => (selectedCategory ? item.previous_solution_category === selectedCategory : true))
      .sort((a, b) => {
        if (sortModel[0].field === 'conversation_date') {
          return sortModel[0].sort === 'asc'
            ? new Date(a.conversation_date) - new Date(b.conversation_date)
            : new Date(b.conversation_date) - new Date(a.conversation_date);
        }
        return 0;
      })
      .slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE);
  }, [filteredAndValidData, selectedCategory, sortModel, page]);

  const totalTablePages = Math.ceil(
    filteredAndValidData.filter((item) => (selectedCategory ? item.previous_solution_category === selectedCategory : true)).length / ITEMS_PER_PAGE
  );

  // Handle row click to open the modal
  const handleRowClick = (customer) => {
    setSelectedCustomer(customer);
    setModalOpen(true);
  };
  
  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Box>
      <Grid container spacing={4}>
        {/* Pie Chart: Previous Solutions */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Previous Solutions</Typography>
          <Typography variant="body2" gutterBottom>
            These are the previous solutions customers mentioned using.
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={previousSolutionData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                label={({ name }) => name}
              >
                {previousSolutionData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={PIE_COLORS[index % PIE_COLORS.length]} />
                ))}
              </Pie>
              <Tooltip
                formatter={(value, name, entry) => [`${value} (${entry.payload.percentage}%)`, 'Files']}
              />
            </PieChart>
          </ResponsiveContainer>
        </Grid>

        {/* Bar Chart: Stickiness Level */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Stickiness Level of Previous Solutions</Typography>
          <Typography variant="body2" gutterBottom>
            This is a measure of how difficult it is to switch to a new solution. Higher means more difficult.
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart 
              data={stickinessData} 
              margin={{ top: 25, right: 20, left: 20, bottom: 60 }} // Increased left and bottom margins for space
            >
              <CartesianGrid strokeDasharray="3 3" />
              
              {/* X-axis with custom tick component for wrapping */}
              <XAxis 
                dataKey="name" 
                tick={<CustomXAxisTick />} 
                interval={0}
              />

              {/* Y-axis with custom range and labels */}
              <YAxis 
                width={70}
                domain={[1, 5]} 
                ticks={[1, 2, 3, 4, 5]} 
                tickFormatter={(tick) => {
                  if (tick === 1) return '1 Easy to switch';
                  if (tick === 5) return '5 Hard to switch';
                  return tick;
                }}
              />

              <Tooltip />
              <Bar dataKey="averageStickiness" fill={BAR_COLOR} />
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>

      {/* Table Section */}
      <Box mt={4}>
        <Typography variant="h6">Previous Solutions Data</Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Previous Solution Category</InputLabel>
          <Select
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value);
              setPage(1);
            }}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {previousSolutionData.map((item) => (
              <MenuItem key={item.name} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box height={400}>
          <DataGrid
            rows={filteredTableData.map((item, index) => ({
              id: index,
              ...item,
              conversation_date: new Date(item.conversation_date).toLocaleDateString(),
              reasons_not_good_enough: item.reasons_not_good_enough?.join('\n'),
            }))}
            columns={[
              { 
                field: 'customer_name', headerName: 'Name', flex: 1,
                renderCell: (params) => (
                  <Typography
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleRowClick(params.row)}
                  >
                    {params.row.customer_name}
                  </Typography>
                )
              },
              { field: 'conversation_date', headerName: 'Conversation Date', flex: 1, sortable: true },
              { field: 'previous_solution', headerName: 'Previous Solution', flex: 1 },
              { field: 'stickiness_level_of_previous_solution', headerName: 'Stickiness Level', flex: 1, sortable: true },
              { field: 'stickiness_level_of_previous_solution_justification', headerName: 'Justification', flex: 2 },
              { field: 'reasons_not_good_enough', headerName: 'Reasons Not Good Enough', flex: 2, cellClassName: 'wrap-text' },
              { field: 'previous_solution_category', headerName: 'Category', flex: 1 },
            ]}
            pageSize={ITEMS_PER_PAGE}
            rowsPerPageOptions={[5, 10, 20]}
            sortingOrder={['asc', 'desc']}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            pagination
            paginationMode="client"
            rowCount={totalTablePages * ITEMS_PER_PAGE}
            page={page - 1}
            onPageChange={(params) => setPage(params + 1)}
            sx={{
              '& .wrap-text': {
                whiteSpace: 'pre-wrap',
                lineHeight: '1.5',
              },
            }}
          />
        </Box>
      </Box>

      {/* Customer Modal */}
      <CustomerModal
        open={modalOpen}
        onClose={handleModalClose}
        customer={selectedCustomer}
      />
    </Box>
  );
}

export default PreviousSolutions;

import React, { useState, useContext } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, Box, Typography, IconButton
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import NavBar from './components/NavBar';
import FilterBar from './components/FilterBar';
import theme from './theme';
import { DataAndFilterContext } from './context/DataAndFilterContext';
import CustomerModal from './components/CustomerModal'; // Import the new modal component
import CustomTagModal from './components/CustomTagModal'; // Import the CustomTagModal component
import EditIcon from '@mui/icons-material/Edit';

function CustomerConversationsPage() {
  const { filteredData, loading, filterOptions } = useContext(DataAndFilterContext);  // Get filtered conversations and filter options from context
  const [modalOpen, setModalOpen] = useState(false);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedRowForTags, setSelectedRowForTags] = useState(null);
  const [sortDirection, setSortDirection] = useState('desc');

  const handleCustomerClick = (customer) => {
    setSelectedCustomer(customer);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleTagModalOpen = (row) => {
    setSelectedRowForTags(row);
    setTagModalOpen(true);
  };

  const handleTagModalClose = () => {
    setTagModalOpen(false);
  };

  const handleSortClick = () => {
    setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
  };

  const sortedConversations = filteredData.sort((a, b) => {
    return sortDirection === 'asc'
      ? new Date(a.conversation_date) - new Date(b.conversation_date)
      : new Date(b.conversation_date) - new Date(a.conversation_date);
  });

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Customer Conversations
        </Typography>
        <FilterBar />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Customer Name</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={true}
                    direction={sortDirection}
                    onClick={handleSortClick}
                  >
                    Conversation Date
                  </TableSortLabel>
                </TableCell>
                <TableCell>Persona</TableCell>
                <TableCell>Conversation Source</TableCell>
                <TableCell sx={{ maxWidth: 150, whiteSpace: 'normal', wordWrap: 'break-word' }}>Custom Tags</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedConversations.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography
                      sx={{ cursor: 'pointer' }}
                      onClick={() => handleCustomerClick(row)}  // Pass entire customer object
                    >
                      {row.customer_name}
                    </Typography>
                  </TableCell>
                  <TableCell>{row.conversation_date}</TableCell>
                  <TableCell>{row.segmentation_data?.persona}</TableCell>
                  <TableCell>{row.conversation_source}</TableCell>
                  <TableCell sx={{ maxWidth: 150, whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {row.segmentation_data?.custom_tags && row.segmentation_data.custom_tags.length > 0
                      ? row.segmentation_data.custom_tags.join(', ')
                      : 'No tags'}
                    <IconButton onClick={() => handleTagModalOpen(row)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Use the CustomerModal component */}
        <CustomerModal
          open={modalOpen}
          onClose={handleModalClose}
          customer={selectedCustomer}
        />

        {/* Use the CustomTagModal component with availableTags passed as a prop */}
        {selectedRowForTags && (
          <CustomTagModal
            open={tagModalOpen}
            onClose={handleTagModalClose}
            dataFile={selectedRowForTags}
            availableTags={filterOptions.customTags} // Pass availableTags prop here
          />
        )}
      </Box>
    </ThemeProvider>
  );
}

export default CustomerConversationsPage;

import React, { useState, useContext } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Collapse,
  IconButton, Typography, TablePagination, Select, MenuItem, InputLabel, FormControl, TableSortLabel, Link
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { DataAndFilterContext } from '../../context/DataAndFilterContext';
import CustomerModal from '../CustomerModal';

function InsightTable() {
  const { filteredData } = useContext(DataAndFilterContext); // Fetch filtered data from context
  const [openRows, setOpenRows] = useState({});
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedProductArea, setSelectedProductArea] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  // Filtered and sorted insights
  const insights = filteredData.flatMap(conv => [
    ...(conv.jobs_to_be_done?.flatMap(job => job.insights?.map(insight => ({
      ...insight,
      customer_name: conv.customer_name,
      customer_data: conv,
    })) || []) || []),
    ...(conv.miscellaneous_insights?.map(insight => ({
      ...insight,
      customer_name: conv.customer_name,
      customer_data: conv,
    })) || [])
  ]);

  // Handle row expansion toggle
  const handleRowClick = (index) => {
    setOpenRows((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  // Handle sorting
  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Handle customer modal
  const handleCustomerClick = (customer) => {
    setSelectedCustomer(customer);
    setCustomerModalOpen(true);
  };

  const handleCustomerModalClose = () => setCustomerModalOpen(false);

  // Apply filters
  const filteredInsights = insights.filter((insight) => {
    return (
      (selectedCategory.length === 0 || selectedCategory.includes(insight.insight_category)) &&
      (selectedProductArea.length === 0 || selectedProductArea.includes(insight.insight_product_area)) &&
      (selectedType.length === 0 || selectedType.includes(insight.insight_type))
    );
  });

  // Apply sorting
  const sortedInsights = filteredInsights.sort((a, b) => {
    const { key, direction } = sortConfig;
    if (!key) return 0;
    if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
    if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
    return 0;
  });

  // Paginate insights
  const paginatedInsights = sortedInsights.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Handle pagination change
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => setRowsPerPage(parseInt(event.target.value, 10));

  return (
    <Box>
      {/* Section Header */}
      <Typography variant="h5" sx={{ mb: 2 }}>Insights Data</Typography>

      {/* Selectors for filtering */}
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
          <InputLabel>Category</InputLabel>
          <Select
            multiple
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            label="Category"
          >
            {/* Dynamically populate menu items */}
            {[...new Set(insights.map(insight => insight.insight_category))].map((category) => (
              <MenuItem key={category} value={category}>{category}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
          <InputLabel>Product Area</InputLabel>
          <Select
            multiple
            value={selectedProductArea}
            onChange={(e) => setSelectedProductArea(e.target.value)}
            label="Product Area"
          >
            {[...new Set(insights.map(insight => insight.insight_product_area))].map((area) => (
              <MenuItem key={area} value={area}>{area}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
          <InputLabel>Type</InputLabel>
          <Select
            multiple
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
            label="Type"
          >
            {[...new Set(insights.map(insight => insight.insight_type))].map((type) => (
              <MenuItem key={type} value={type}>{type}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {/* Table headers with sort */}
              {['insight', 'insight_quote', 'insight_type', 'insight_category', 'insight_product_area', 'insight_sentiment_score'].map((col) => (
                <TableCell key={col}>
                  <TableSortLabel
                    active={sortConfig.key === col}
                    direction={sortConfig.direction}
                    onClick={() => handleSort(col)}
                  >
                    {col.replace(/_/g, ' ').toUpperCase()}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedInsights.map((insight, index) => (
              <React.Fragment key={index}>
                <TableRow hover>
                  <TableCell>{insight.insight}</TableCell>
                  <TableCell>"{insight.insight_quote}"</TableCell>
                  <TableCell>{insight.insight_type}</TableCell>
                  <TableCell>{insight.insight_category}</TableCell>
                  <TableCell>{insight.insight_product_area}</TableCell>
                  <TableCell>
                    {insight.insight_sentiment_score - 5 > 0 ? '+' : ''}
                    {insight.insight_sentiment_score - 5}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleRowClick(index)}>
                      {openRows[index] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                          Insight Details
                        </Typography>
                        {/* Detail content similar to modal structure */}
                        <Paper sx={{ p: 2 }}>
                          <Typography variant="body1" fontWeight="bold" gutterBottom>
                            "{insight.insight_quote}"
                          </Typography>
                          <Link
                            href="#"
                            variant="body2"
                            underline="hover"
                            sx={{ color: 'secondary.main', display: 'block', mb: 1 }}
                          >
                            Timestamps: {insight.insight_timestamps}
                          </Link>
                          <Box sx={{ mb: 2 }}>
                            <Typography variant="body2">Summary: {insight.insight_summary}</Typography>
                          </Box>
                          <Box sx={{ mb: 2 }}>
                            <Typography variant="body2">Type: {insight.insight_type}</Typography>
                            <Typography variant="body2">Product Area: {insight.insight_product_area}</Typography>
                            <Typography variant="body2">Category: {insight.insight_category}</Typography>
                          </Box>
                          <Box>
                            <Typography variant="body2">Sentiment Overall: {insight.insight_sentiment_overall}</Typography>
                            <Typography variant="body2">Sentiment Expressed: {insight.insight_sentiment_expressed}</Typography>
                            <Typography variant="body2">
                              Sentiment Score: {insight.insight_sentiment_score - 5 > 0 ? '+' : ''}
                              {insight.insight_sentiment_score - 5}
                            </Typography>
                            <Typography variant="body2">Sentiment Justification: {insight.insight_sentiment_justification}</Typography>
                          </Box>
                          <Typography
                            variant="body2"
                            sx={{ cursor: 'pointer', color: 'primary.main', mt: 2 }}
                            onClick={() => handleCustomerClick(insight.customer_data)}
                          >
                            Customer: {insight.customer_name}
                          </Typography>
                        </Paper>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={filteredInsights.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {/* Customer Modal */}
      <CustomerModal
        open={customerModalOpen}
        onClose={handleCustomerModalClose}
        customer={selectedCustomer}
      />
    </Box>
  );
}

export default InsightTable;

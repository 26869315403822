import React, { useContext, useMemo } from 'react';
import { DataAndFilterContext } from '../../context/DataAndFilterContext';
import WordCloud from 'react-wordcloud';
import { Box, Typography, Grid } from '@mui/material';

// Helper function to split a string into words and count occurrences
const countWords = (text) => {
  const words = text
    .toLowerCase()
    .replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, "") // Remove punctuation
    .split(/\s+/); // Split by whitespace
  const wordCount = {};
  words.forEach((word) => {
    if (word.length > 2) { // Filter out very short words
      wordCount[word] = (wordCount[word] || 0) + 1;
    }
  });
  return wordCount;
};

function NeedsWordClouds() {
  const { filteredData } = useContext(DataAndFilterContext);

  // Prepare word cloud data for each job category
  const wordCloudData = useMemo(() => {
    const jobWordCounts = {};

    filteredData.forEach((file) => {
      file.jobs_to_be_done.forEach((job) => {
        const category = job.job_to_be_done_category;
        const satisfactionMeasure = job.how_they_measure_satisfaction;

        if (satisfactionMeasure) {
          if (!jobWordCounts[category]) {
            jobWordCounts[category] = {};
          }

          // Count words in the "how_they_measure_satisfaction" field
          const wordCount = countWords(satisfactionMeasure);
          Object.keys(wordCount).forEach((word) => {
            jobWordCounts[category][word] = (jobWordCounts[category][word] || 0) + wordCount[word];
          });
        }
      });
    });

    // Transform the word counts into arrays suitable for react-wordcloud
    const transformData = (wordCounts) => {
      return Object.entries(wordCounts).map(([text, value]) => ({ text, value }));
    };

    return Object.keys(jobWordCounts).map((category) => ({
      category,
      words: transformData(jobWordCounts[category]),
    }));
  }, [filteredData]);

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Words to describe how customers measure success in their Jobs To Be Done</Typography>
      <Typography variant="h7">The biggest words are the most-used descriptions of successly doing a Job</Typography>
      <br /><br />
      <Grid container spacing={3}>
        {wordCloudData.map(({ category, words }) => (
          <Grid item xs={12} md={6} key={category}>
            <Typography variant="h6">Job: {category}</Typography>
            <Box style={{ height: '300px', width: '100%' }}>
              <WordCloud
                words={words}
                options={{
                  rotations: 2,
                  rotationAngles: [-90, 0],
                  scale: 'sqrt',
                  spiral: 'rectangular',
                  fontSizes: [15, 50],
                }}
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default NeedsWordClouds;

import React, { useState } from 'react';
import { Button, Box, Typography, TextField, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import NavBar from '../components/NavBar';
import theme from '../theme';

const interviewLengths = ['5m', '10m', '20m', '30m'];

const ProjectCreationFlow = () => {
  const [step, setStep] = useState(1);
  const [projectDetails, setProjectDetails] = useState({
    name: '',
    targetAudience: '',
    learningGoal: '',
    interviewLength: '',
    discussionGuide: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleNextStep = async () => {
    if (step === 4) {
      // Generate discussion guide before moving to Step 5
      setIsLoading(true);
      await generateDiscussionGuide();
      setIsLoading(false);
    }

    if (step === 6) {
      // Final step - create project and navigate to the projects page
      alert('Project created successfully!');
      navigate('/manage-projects');
    } else {
      setStep((prev) => prev + 1);
    }
  };

  const handleBackStep = () => {
    if (step > 1) {
      setStep((prev) => prev - 1);
    }
  };

  const handleCreateProject = async () => {
    const token = localStorage.getItem('access_token');
    console.log('Token:', token);
    if (!token) {
      alert('Authentication token is missing. Please log in.');
      navigate('/login');
      return;
    }
  
    const projectData = {
      project_name: projectDetails.name,
      target_audience: projectDetails.targetAudience,
      learning_goal: projectDetails.learningGoal,
      interview_length: projectDetails.interviewLength,
      discussion_guide: projectDetails.discussionGuide,
    };
  
    try {
      const response = await fetch('/api/create-project', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(projectData),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create project');
      }
  
      alert('Project created successfully!');
      navigate('/manage-projects');
    } catch (error) {
      console.error('Error creating project:', error);
      alert(`An error occurred while creating the project: ${error.message}`);
    }
  };  

  const generateDiscussionGuide = async () => {
    try {
      const token = localStorage.getItem('access_token');
      console.log('Token:', token);
      if (!token) {
        alert('Authentication token is missing. Please log in.');
        navigate('/login');
        return;
      }

      console.log('Sending data:', {
        audience_description: projectDetails.targetAudience,
        learning_goals: projectDetails.learningGoal,
        interview_length: projectDetails.interviewLength,
      });

      const response = await fetch('/api/generate-discussion-guide', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          audience_description: projectDetails.targetAudience,
          learning_goals: projectDetails.learningGoal,
          interview_length: projectDetails.interviewLength,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to generate discussion guide');
      }

      const data = await response.json();
      console.log('Received data:', data);

      // Update the state with the discussion guide content
      setProjectDetails({ ...projectDetails, discussionGuide: data.guide });
    } catch (error) {
      console.error('Error generating discussion guide:', error);
      alert(`An error occurred while generating the discussion guide: ${error.message}`);
    }
  };

  const handleInputChange = (field, value) => {
    setProjectDetails({ ...projectDetails, [field]: value });
  };

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Box sx={{ padding: 3 }}>
        {step === 1 && (
          <>
            <Typography variant="h5">Step 1: Name Your Project</Typography>
            <TextField
              label="Project Name"
              fullWidth
              margin="normal"
              value={projectDetails.name}
              onChange={(e) => handleInputChange('name', e.target.value)}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
              <Button variant="contained" onClick={handleBackStep} disabled={step === 1}>
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleNextStep}>
                Next
              </Button>
            </Box>
          </>
        )}
        {step === 2 && (
          <>
            <Typography variant="h5">Step 2: Describe the Target Audience</Typography>
            <TextField
              label="Our participants are:"
              fullWidth
              margin="normal"
              value={projectDetails.targetAudience}
              onChange={(e) => handleInputChange('targetAudience', e.target.value)}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
              <Button variant="contained" onClick={handleBackStep}>
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleNextStep}>
                Next
              </Button>
            </Box>
          </>
        )}
        {step === 3 && (
          <>
            <Typography variant="h5">Step 3: Define the Learning Goal</Typography>
            <TextField
              label="We are trying to learn:"
              fullWidth
              margin="normal"
              value={projectDetails.learningGoal}
              onChange={(e) => handleInputChange('learningGoal', e.target.value)}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
              <Button variant="contained" onClick={handleBackStep}>
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleNextStep}>
                Next
              </Button>
            </Box>
          </>
        )}
        {step === 4 && (
          <>
            <Typography variant="h5">Step 4: Choose Interview Length</Typography>
            <TextField
              select
              label="Interview Length"
              fullWidth
              margin="normal"
              value={projectDetails.interviewLength}
              onChange={(e) => handleInputChange('interviewLength', e.target.value)}
            >
              {interviewLengths.map((length) => (
                <MenuItem key={length} value={length}>
                  {length}
                </MenuItem>
              ))}
            </TextField>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
              <Button variant="contained" onClick={handleBackStep}>
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNextStep}
                disabled={isLoading}
              >
                {isLoading ? 'Generating...' : 'Next'}
              </Button>
            </Box>
          </>
        )}
        {step === 5 && (
          <>
            <Typography variant="h5">Step 5: Review Discussion Guide</Typography>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              This is the generated discussion guide based on your inputs. You can edit the discussion guide below before continuing.
            </Typography>
            <TextField
              label="Discussion Guide"
              multiline
              rows={6}
              fullWidth
              value={projectDetails.discussionGuide}
              onChange={(e) => handleInputChange('discussionGuide', e.target.value)}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
              <Button variant="contained" onClick={handleBackStep}>
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNextStep}
              >
                Next
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={async () => {
                  setIsLoading(true);
                  await generateDiscussionGuide();
                  setIsLoading(false);
                }}
                disabled={isLoading}
              >
                {isLoading ? 'Regenerating...' : 'Regenerate'}
              </Button>
            </Box>
          </>
        )}
        {step === 6 && (
          <>
            <Typography variant="h5">Step 6: Review and Confirm</Typography>
            <Typography variant="body1">
              Project Name: {projectDetails.name}
            </Typography>
            <Typography variant="body1">
              Target Audience: {projectDetails.targetAudience}
            </Typography>
            <Typography variant="body1">
              Learning Goal: {projectDetails.learningGoal}
            </Typography>
            <Typography variant="body1">
              Interview Length: {projectDetails.interviewLength}
            </Typography>
            <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
              Discussion Guide: {projectDetails.discussionGuide}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
              <Button variant="contained" onClick={handleBackStep}>
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleCreateProject}>
                Create Project
              </Button>
            </Box>
          </>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default ProjectCreationFlow;

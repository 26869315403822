import React, { useEffect, useState, useContext } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { format, parse, isWithinInterval, eachMonthOfInterval, subMonths } from 'date-fns';
import { DataAndFilterContext } from '../../context/DataAndFilterContext';
import chartColors from '../../theme/chartColors'; // Import the color palette

function TriggersStackedBarChart() {
  const { filteredData, filters } = useContext(DataAndFilterContext); // Access dateRange and customDateRange from filters
  const [chartData, setChartData] = useState([]);
  const [uniqueCategories, setUniqueCategories] = useState([]);

  // Helper function to check if a date falls within the selected range
  const isDateInRange = (date) => {
    const conversationDate = parse(date, 'MM-dd-yyyy', new Date()); // Parse the date with format "MM-DD-YYYY"
    if (filters.dateRange === 'Custom' && filters.customDateRange.from && filters.customDateRange.to) {
      const { from, to } = filters.customDateRange;
      return isWithinInterval(conversationDate, { start: new Date(from), end: new Date(to) });
    }
    // Return true if the data should always show
    return true;
  };

  // Function to generate an array of all months in the selected date range
  const getMonthsInRange = () => {
    let startDate;
    let endDate;

    if (filters.dateRange === '6 months') {
      endDate = new Date();
      startDate = subMonths(endDate, 6);
    } else if (filters.dateRange === '3 months') {
      endDate = new Date();
      startDate = subMonths(endDate, 3);
    } else if (filters.dateRange === 'Custom' && filters.customDateRange.from && filters.customDateRange.to) {
      startDate = new Date(filters.customDateRange.from);
      endDate = new Date(filters.customDateRange.to);
    } else {
      // Default to 'All time'
      endDate = new Date();
      startDate = subMonths(endDate, 12); // Change this to whatever makes sense for "All time"
    }

    return eachMonthOfInterval({ start: startDate, end: endDate });
  };

  // Process data based on trigger_category and date filtering
  const processChartData = () => {
    const monthsInRange = getMonthsInRange(); // Get all months in the range
    const dataMap = {};
    const categorySet = new Set(); // To collect unique trigger_category values dynamically

    // Initialize each month in the range to ensure all months show up in the chart
    monthsInRange.forEach((month) => {
      const monthLabel = format(month, "MMM ''yy"); // Format the date as "Oct '24"
      dataMap[monthLabel] = { month: monthLabel };
    });

    // Iterate through filtered data and fill in the dataMap
    filteredData.forEach((conversation) => {
      const { conversation_date, trigger_category } = conversation;
      const parsedDate = parse(conversation_date, 'MM-dd-yyyy', new Date()); // Parse the conversation date
      const conversationMonth = format(parsedDate, "MMM ''yy"); // Format the date as "Oct '24"

      if (isDateInRange(conversation_date) && trigger_category) {
        // Ensure the month and trigger_category are initialized
        if (!dataMap[conversationMonth]) {
          dataMap[conversationMonth] = { month: conversationMonth };
        }
        if (!dataMap[conversationMonth][trigger_category]) {
          dataMap[conversationMonth][trigger_category] = 0;
        }

        // Increment the count for the trigger_category
        dataMap[conversationMonth][trigger_category] += 1;

        // Collect unique trigger categories
        categorySet.add(trigger_category);
      }
    });

    // Convert the dataMap to an array and set the chartData
    const result = Object.keys(dataMap).map((month) => dataMap[month]);

    // Sort months by date order
    result.sort((a, b) => {
      const dateA = parse(a.month, "MMM ''yy", new Date());
      const dateB = parse(b.month, "MMM ''yy", new Date());
      return dateA - dateB;
    });

    setChartData(result);
    setUniqueCategories(Array.from(categorySet)); // Convert the Set to an array for dynamic Bars
  };

  useEffect(() => {
    processChartData();
  }, [filteredData, filters]); // Recalculate if filteredData or filters change

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Legend />
        {/* Dynamically generate Bar components for each trigger_category */}
        {uniqueCategories.map((category, index) => (
          <Bar
            key={category}
            dataKey={category}
            stackId="a"
            fill={chartColors[index % chartColors.length]} // Use colors from the color palette
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default TriggersStackedBarChart;

import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Modal,
  TextField,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import NavBar from './components/NavBar';
import theme from './theme';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for routing

const PersonaProfiles = () => {
  const [personas, setPersonas] = useState([]); // Initialize as empty array
  const [personaCounts, setPersonaCounts] = useState({});
  const [selectedPersonaId, setSelectedPersonaId] = useState(null);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageInstructions, setImageInstructions] = useState("");
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [loading, setLoading] = useState(true);
  const [isImageProcessing, setIsImageProcessing] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  useEffect(() => {
    // Fetch personas and persona counts from the backend
    const fetchPersonas = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch('/api/persona_profiles', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setPersonas(data.personas);
          setPersonaCounts(data.persona_counts);
        } else {
          console.error('Failed to fetch personas:', response.status);
        }
      } catch (error) {
        console.error('Error fetching personas:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchPersonas();
  }, []);

  const handleGenerateImage = (personaId) => {
    setSelectedPersonaId(personaId);
    setOpenImageModal(true);
  };

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
    setSelectedPersonaId(null);
  };

  const handleSubmitImageForm = async (e) => {
    e.preventDefault();
    setIsImageProcessing(true); // Start processing, disable form
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch("/api/generate_image", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          persona_id: selectedPersonaId,
          instructions: imageInstructions,
        }),
      });
      if (response.ok) {
        alert("Image generated successfully");
        setOpenImageModal(false);
        window.location.reload(); // Reload the page to see the new image
      } else {
        alert("Error generating image");
      }
    } catch (error) {
      console.error("Error generating image:", error);
    } finally {
      setIsImageProcessing(false); // Stop processing, re-enable form
    }
  };

  const [isProcessing, setIsProcessing] = useState(false);

  const handleGeneratePersonas = async (e) => {
    e.preventDefault();
    const userConfirmed = window.confirm(
      "This will overwrite your existing personas. Proceed?"
    );
    if (userConfirmed) {
      setIsProcessing(true); // Disable input and button
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch("/api/generate_personas", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ special_instructions: specialInstructions }),
        });
        if (response.ok) {
          alert("Personas generated successfully");
          window.location.reload(); 
        } else {
          alert("Error generating personas");
        }
      } catch (error) {
        console.error("Error generating personas:", error);
      } finally {
        setIsProcessing(false); // Re-enable the form elements after processing
      }
    }
  };

  const [hoveredCard, setHoveredCard] = useState(null);

  // Navigate to PersonaProfile.js when "View Details" is clicked
  const handleViewDetails = (personaId) => {
    navigate(`/persona/${personaId}`); // Use personaId for navigation
  };

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4">Persona Profiles</Typography>
        <Typography paragraph>
          Personas are used to segment your data for analysis. Personas are
          weighted more towards a user’s Jobs To Be Done than other factors
          because needs-based segmentation is the best way to think about your
          customers.
        </Typography>
        {loading && (
          <Typography paragraph>Loading personas...</Typography>
        )}

        {!loading && personas.length === 0 && (
          <Typography paragraph>No personas generated yet. Please generate personas.</Typography>
        )}
        <Box display="flex" flexWrap="wrap">
          {personas.map((persona) => (
            <Card 
              key={persona.persona_id} 
              onMouseEnter={() => { 
                setHoveredCard(persona.persona_id);
              }}
              onMouseLeave={() => { 
                setHoveredCard(null);
              }}
              sx={{
                margin: "10px", 
                width: 300, 
                position: "relative",
              }}
            >
              <CardMedia
                component="img"
                height="200"
                image={
                  persona.image_filename
                    ? `/static/persona_photos/${persona.image_filename}`
                    : "/static/persona_photos/blank.png"
                }
                alt={persona.name}
              />
              <Button
                onClick={() => handleGenerateImage(persona.persona_id)}
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  zIndex: 2, 
                  backgroundColor: "rgba(255, 255, 255, 0.8)", 
                  visibility: hoveredCard === persona.persona_id ? "visible" : "hidden",
                  transition: "visibility 0.3s ease-in-out",
                }}
              >
                <i className="fas fa-plus-circle">New image</i>
              </Button>
              <CardContent>
                <Typography variant="h5">{persona.name}</Typography>
                <Typography paragraph>{persona.description}</Typography>
                <Typography paragraph>
                  Total customers past 90 days: {personaCounts[persona.name] || 0}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleViewDetails(persona.persona_id)} // Pass personaId
                >
                  View Details
                </Button>
              </CardContent>
            </Card>
          ))}
        </Box>

        {/* Modal for generating image */}
        <Modal open={openImageModal} onClose={handleCloseImageModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              backgroundColor: "white",
              padding: 4,
              borderRadius: 4,
            }}
          >
            <Typography variant="h6">Generate Image for Persona</Typography>
            <form onSubmit={handleSubmitImageForm}>
              <TextField
                label="Instructions (optional)"
                value={imageInstructions}
                onChange={(e) => setImageInstructions(e.target.value)}
                fullWidth
                margin="normal"
                disabled={isImageProcessing}  // Disable form while processing
              />
              <Button
                variant="contained"
                type="submit"
                disabled={isImageProcessing}  // Disable button while processing
              >
                {isImageProcessing ? "Processing..." : "Generate Image"}
              </Button>
            </form>
          </Box>
        </Modal>

        {/* Form to generate new personas */}
        <Box mt={5}>
          <Typography variant="h5">Generate new personas</Typography>
          <Typography paragraph>
            We recommend you generate Personas after your first 5-8 files,
            then re-generate your Personas after every ~10 files for finer segmentation.
          </Typography>
          <form onSubmit={handleGeneratePersonas}>
            <TextField
              label="Special Instructions"
              value={specialInstructions}
              onChange={(e) => setSpecialInstructions(e.target.value)}
              fullWidth
              margin="normal"
              disabled={isProcessing}
            />
            <Button
              variant="contained"
              color="error"
              type="submit"
              disabled={isProcessing}
            >
              {isProcessing ? "Processing..." : "Generate new personas"}
            </Button>
          </form>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default PersonaProfiles;

import React, { useMemo, useState } from 'react';
import { Box, Typography, Grid, Modal, Paper, Link } from '@mui/material';
import CustomerModal from '../CustomerModal'; // Ensure this component is correctly imported

// Extended helper function to return drawbacks along with average and count
const calculateAverageAndCount = (data, solutionCategory, drawbackCategory) => {
  const matchingItems = data
    .filter(
      (item) =>
        item.solution_choice_category === solutionCategory &&
        item.drawbacks_of_solution_choice.some(
          (drawback) => drawback.drawback_category === drawbackCategory
        )
    )
    .flatMap((item) =>
      item.drawbacks_of_solution_choice
        .filter((drawback) => drawback.drawback_category === drawbackCategory)
        .map((drawback) => ({
          ...drawback,
          customer_name: item.customer_name,
          customer_data: item,
        }))
    );

  if (matchingItems.length === 0) return { average: null, count: 0, drawbacks: [] };

  const totalImportance = matchingItems.reduce(
    (acc, drawback) => acc + drawback.drawback_importance,
    0
  );

  const average = totalImportance / matchingItems.length;

  return { average, count: matchingItems.length, drawbacks: matchingItems };
};

const DrawbacksHeatmap = ({ filteredData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedDrawbacks, setSelectedDrawbacks] = useState([]);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const solutionCategories = [...new Set(filteredData.map((item) => item.solution_choice_category))];
  const drawbackCategories = [
    ...new Set(
      filteredData.flatMap((item) =>
        item.drawbacks_of_solution_choice.map((drawback) => drawback.drawback_category)
      )
    ),
  ];

  const { minValue, maxValue } = useMemo(() => {
    let min = Infinity;
    let max = -Infinity;

    filteredData.forEach((item) => {
      item.drawbacks_of_solution_choice.forEach((drawback) => {
        if (drawback.drawback_importance < min) min = drawback.drawback_importance;
        if (drawback.drawback_importance > max) max = drawback.drawback_importance;
      });
    });
    return { minValue: min, maxValue: max };
  }, [filteredData]);

  const getCellColor = (value) => {
    if (value === null) return '#D3D3D3';
    const intensity = (value - minValue) / (maxValue - minValue);
    const red = 255;
    const green = 255 - Math.round(intensity * 255);
    const blue = 255 - Math.round(intensity * 255);
    return `rgb(${red}, ${green}, ${blue})`;
  };

  const handleCellClick = (drawbacks) => {
    if (drawbacks.length > 0) {
      setSelectedDrawbacks(drawbacks);
      setOpenModal(true);
    }
  };

  const handleClose = () => setOpenModal(false);

  const handleCustomerClick = (customer) => {
    setSelectedCustomer(customer);
    setCustomerModalOpen(true);
  };

  const handleCustomerModalClose = () => setCustomerModalOpen(false);

  return (
    <Box>
      <Grid container>
        <Grid item xs={2}></Grid>
        {solutionCategories.map((solution, index) => (
          <Grid item xs key={`header-${index}`}>
            <Typography variant="subtitle2" align="center">{solution}</Typography>
          </Grid>
        ))}
      </Grid>

      {drawbackCategories.map((drawbackCategory, rowIndex) => (
        <Grid container key={`row-${rowIndex}`}>
          <Grid item xs={2}>
            <Typography variant="subtitle2">{drawbackCategory}</Typography>
          </Grid>
          {solutionCategories.map((solutionCategory, colIndex) => {
            const { average, count, drawbacks } = calculateAverageAndCount(
              filteredData,
              solutionCategory,
              drawbackCategory
            );
            const cellColor = getCellColor(average);

            return (
              <Grid
                item
                xs
                key={`cell-${rowIndex}-${colIndex}`}
                style={{
                  backgroundColor: cellColor,
                  border: '1px solid #ccc',
                  padding: '8px',
                  textAlign: 'center',
                  cursor: drawbacks.length > 0 ? 'pointer' : 'default',
                }}
                onClick={() => handleCellClick(drawbacks)}
              >
                {average !== null ? (
                  <>
                    <Typography variant="body2">{average.toFixed(2)}</Typography>
                    <Typography variant="caption" style={{ fontSize: '0.75rem' }}>
                      ({count} {count === 1 ? 'conversation' : 'conversations'})
                    </Typography>
                  </>
                ) : (
                  'n/a'
                )}
              </Grid>
            );
          })}
        </Grid>
      ))}

      <Modal open={openModal} onClose={handleClose}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%', bgcolor: 'background.paper', boxShadow: 24, p: 4, maxHeight: '80vh', overflowY: 'auto' }}>
          <Typography variant="h6">Drawback Details</Typography>
          {selectedDrawbacks.map((drawback, index) => (
            <Paper key={index} sx={{ p: 2, mb: 2 }}>
              <Typography variant="body1" fontWeight="bold" gutterBottom>
                "{drawback.drawback_importance_quote}"
              </Typography>
              <Typography variant="h6" gutterBottom>{drawback.drawback}</Typography>

              <Box sx={{ mb: 2 }}>
                <Typography variant="body2">Category: {drawback.drawback_category}</Typography>
                <Typography variant="body2">Importance: {drawback.drawback_importance}</Typography>
                <Typography variant="body2">Justification: {drawback.drawback_importance_justification}</Typography>
                <Link href="#" variant="body2" underline="hover" sx={{ color: 'secondary.main', display: 'block', mb: 1 }}>
                  Timestamps: {drawback.drawback_timestamps}
                </Link>
              </Box>

              <Typography
                variant="body2"
                sx={{ cursor: 'pointer', color: 'primary.main', mt: 2 }}
                onClick={() => handleCustomerClick(drawback.customer_data)}
              >
                Customer: {drawback.customer_name}
              </Typography>
            </Paper>
          ))}
        </Box>
      </Modal>

      <CustomerModal open={customerModalOpen} onClose={handleCustomerModalClose} customer={selectedCustomer} />
    </Box>
  );
};

export default DrawbacksHeatmap;

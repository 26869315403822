import React, { useState, useEffect } from 'react';
import {
  Box, Typography, List, ListItem, ListItemText, Alert, CircularProgress
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import NavBar from './components/NavBar';
import theme from './theme';

function IntegrationAlerts() {
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch('/api/get-alerts', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setAlerts(data.alerts);
        } else {
          setError('Failed to load alerts.');
        }
      } catch (err) {
        setError('An error occurred while fetching alerts.');
      } finally {
        setLoading(false);
      }
    };

    fetchAlerts();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Integration Alerts
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <List>
            {alerts.map((alert, index) => (
              <ListItem key={index}>
                <ListItemText primary={alert.message} secondary={`Type: ${alert.type} - Date: ${alert.date}`} />
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </ThemeProvider>
  );
}

export default IntegrationAlerts;

import React, { createContext, useState, useEffect } from 'react';
import { useGlobalLoading } from './GlobalLoadingContext'; // Import the global loading context

export const DataAndFilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    conversation_sources: [],
    personas: [],
    jobsToBeDone: [],
    desiredOutcomes: [],
    solutionChoices: [],
    customTags: [],
  });
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    selectedSource: [],
    selectedPersona: [],
    selectedJobToBeDone: [],
    selectedDesiredOutcome: [],
    selectedSolutionChoice: [],
    selectedCustomTags: [],
    dateRange: 'All time',
    customDateRange: { from: null, to: null },
  });

  const { startLoading, stopLoading } = useGlobalLoading();

  const fetchUniqueTags = async () => {
    try {
      startLoading();
      const response = await fetch('/api/conversations', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        },
      });

      if (response.ok) {
        const result = await response.json();
        const uniqueCustomTags = new Set();
        result.filtered_data.forEach((conv) => {
          conv.segmentation_data?.custom_tags?.forEach((tag) => {
            uniqueCustomTags.add(tag);
          });
        });

        setFilterOptions((prevOptions) => ({
          ...prevOptions,
          customTags: Array.from(uniqueCustomTags),
        }));
      } else {
        console.error('Failed to fetch unique tags:', response.status);
      }
    } catch (error) {
      console.error('Error fetching unique tags:', error);
    } finally {
      stopLoading();
    }
  };

  const updateDataFile = (filename, updatedFileData) => {
    setData((prevData) =>
      prevData.map((file) => (file.filename === filename ? updatedFileData : file))
    );
    setFilteredData((prevFilteredData) =>
      prevFilteredData.map((file) => (file.filename === filename ? updatedFileData : file))
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        startLoading();
        const token = localStorage.getItem('access_token');
        const response = await fetch('/api/conversations', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const result = await response.json();
          setData(result.filtered_data);

          const uniqueJobs = new Set();
          const uniqueOutcomes = new Set();
          const uniqueSolutions = new Set();
          const uniqueCustomTags = new Set();

          result.filtered_data.forEach((conv) => {
            conv.jobs_to_be_done?.forEach((job) => {
              uniqueJobs.add(job.job_to_be_done_category);
              uniqueOutcomes.add(job.desired_outcome_category);
            });
            if (conv.solution_choice_category) {
              uniqueSolutions.add(conv.solution_choice_category);
            }
            conv.segmentation_data?.custom_tags?.forEach((tag) => {
              uniqueCustomTags.add(tag);
            });
          });

          setFilterOptions({
            conversation_sources: result.filter_options.conversation_sources,
            personas: result.filter_options.personas,
            jobsToBeDone: Array.from(uniqueJobs),
            desiredOutcomes: Array.from(uniqueOutcomes),
            solutionChoices: Array.from(uniqueSolutions),
            customTags: Array.from(uniqueCustomTags),
          });

          setFilteredData(result.filtered_data);
        } else {
          console.error('Failed to fetch data:', response.status);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const applyFilters = () => {
      let filtered = data;

      if (filters.selectedSource.length > 0) {
        filtered = filtered.filter(conv => filters.selectedSource.includes(conv.conversation_source));
      }
      if (filters.selectedPersona.length > 0) {
        filtered = filtered.filter(conv => filters.selectedPersona.includes(conv.segmentation_data?.persona));
      }
      if (filters.selectedJobToBeDone.length > 0) {
        filtered = filtered.filter(conv => conv.jobs_to_be_done?.some(job => filters.selectedJobToBeDone.includes(job.job_to_be_done_category)));
      }
      if (filters.selectedDesiredOutcome.length > 0) {
        filtered = filtered.filter(conv => conv.jobs_to_be_done?.some(job => filters.selectedDesiredOutcome.includes(job.desired_outcome_category)));
      }
      if (filters.selectedSolutionChoice.length > 0) {
        filtered = filtered.filter(conv => filters.selectedSolutionChoice.includes(conv.solution_choice_category));
      }
      if (filters.selectedCustomTags.length > 0) {
        filtered = filtered.filter(conv => conv.segmentation_data?.custom_tags?.some(tag => filters.selectedCustomTags.includes(tag)));
      }

      const currentDate = new Date();
      if (filters.dateRange === '6 months') {
        const sixMonthsAgo = new Date(currentDate.setMonth(currentDate.getMonth() - 6));
        filtered = filtered.filter(conv => new Date(conv.conversation_date) >= sixMonthsAgo);
      } else if (filters.dateRange === '3 months') {
        const threeMonthsAgo = new Date(currentDate.setMonth(currentDate.getMonth() - 3));
        filtered = filtered.filter(conv => new Date(conv.conversation_date) >= threeMonthsAgo);
      } else if (filters.dateRange === 'Custom' && filters.customDateRange.from && filters.customDateRange.to) {
        const fromDate = new Date(filters.customDateRange.from);
        const toDate = new Date(filters.customDateRange.to);
        filtered = filtered.filter(conv => new Date(conv.conversation_date) >= fromDate && new Date(conv.conversation_date) <= toDate);
      }

      setFilteredData(filtered);
    };

    applyFilters();
  }, [filters, data]);

  const updateFilters = (newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  const clearFilters = () => {
    setFilters({
      selectedSource: [],
      selectedPersona: [],
      selectedJobToBeDone: [],
      selectedDesiredOutcome: [],
      selectedSolutionChoice: [],
      selectedCustomTags: [],
      dateRange: 'All time',
      customDateRange: { from: null, to: null },
    });
  };

  return (
    <DataAndFilterContext.Provider value={{ filterOptions, filters, filteredData, updateFilters, clearFilters, fetchUniqueTags, updateDataFile }}>
      {children}
    </DataAndFilterContext.Provider>
  );
};

import React, { useContext, useMemo, useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import { Checkbox, FormControlLabel, Box, Typography } from '@mui/material';
import { DataAndFilterContext } from '../../context/DataAndFilterContext';
import { format, eachMonthOfInterval, subMonths } from 'date-fns';

const InsightsTrendsCharts = () => {
  const { filteredData, filters } = useContext(DataAndFilterContext);

  const formatMonth = (date) => format(date, "MMM ''yy");

  const monthsInRange = useMemo(() => {
    const defaultEndDate = new Date();
    let startDate, endDate = defaultEndDate;

    if (filters.dateRange === '6 months') {
      startDate = subMonths(endDate, 6);
    } else if (filters.dateRange === '3 months') {
      startDate = subMonths(endDate, 3);
    } else if (filters.dateRange === 'Custom' && filters.customDateRange.from && filters.customDateRange.to) {
      startDate = new Date(filters.customDateRange.from);
      endDate = new Date(filters.customDateRange.to);
    } else {
      const earliestDate = filteredData.reduce((earliest, item) => {
        const conversationDate = new Date(item.conversation_date);
        return conversationDate < earliest ? conversationDate : earliest;
      }, new Date());
      startDate = earliestDate;
    }

    return eachMonthOfInterval({ start: startDate, end: endDate }).map((month) => ({
      monthKey: format(month, 'yyyy-MM'),
      formatted: formatMonth(month),
    }));
  }, [filters]);

  const { sentimentData, categories, initialVisibility, categorySentiment } = useMemo(() => {
    const categorySentiment = {};

    filteredData.forEach((file) => {
      const fileDate = new Date(file.conversation_date);
      const monthKey = format(fileDate, 'yyyy-MM');

      file.jobs_to_be_done.forEach((job) => {
        job.insights?.forEach((insight) => {
          const category = insight.insight_category;
          const score = insight.insight_sentiment_score - 3; // Adjust sentiment score by subtracting 3

          if (!categorySentiment[category]) categorySentiment[category] = {};
          if (!categorySentiment[category][monthKey]) categorySentiment[category][monthKey] = { totalScore: 0, count: 0 };

          categorySentiment[category][monthKey].totalScore += score;
          categorySentiment[category][monthKey].count += 1;
        });
      });

      file.miscellaneous_insights?.forEach((insight) => {
        const category = insight.insight_category;
        const score = insight.insight_sentiment_score - 3; // Adjust sentiment score by subtracting 3

        if (!categorySentiment[category]) categorySentiment[category] = {};
        if (!categorySentiment[category][monthKey]) categorySentiment[category][monthKey] = { totalScore: 0, count: 0 };

        categorySentiment[category][monthKey].totalScore += score;
        categorySentiment[category][monthKey].count += 1;
      });
    });

    const sentimentData = monthsInRange.map(({ monthKey, formatted }) => {
      const entry = { month: formatted };
      for (const category in categorySentiment) {
        const sentimentEntry = categorySentiment[category][monthKey];
        entry[category] = sentimentEntry && sentimentEntry.count > 0
          ? (sentimentEntry.totalScore / sentimentEntry.count).toFixed(1)
          : 0;
        entry[`${category}_count`] = sentimentEntry ? sentimentEntry.count : 0; // Store count for tooltip
      }
      return entry;
    });

    const categoryCounts = Object.entries(categorySentiment).map(([category, stats]) => ({
      category,
      count: Object.values(stats).reduce((sum, entry) => sum + (entry?.count || 0), 0),
    }));
    categoryCounts.sort((a, b) => b.count - a.count);
    const top5Categories = categoryCounts.slice(0, 5).map((item) => item.category);

    const initialVisibility = {};
    categoryCounts.forEach(({ category }) => {
      initialVisibility[category] = top5Categories.includes(category);
    });

    return { sentimentData, categories: categoryCounts.map(({ category }) => category), initialVisibility, categorySentiment };
  }, [filteredData, filters, monthsInRange]);

  const [visibleCategories, setVisibleCategories] = useState(initialVisibility);

  useEffect(() => setVisibleCategories(initialVisibility), [initialVisibility]);

  const handleCheckboxChange = (category) => setVisibleCategories((prev) => ({ ...prev, [category]: !prev[category] }));

  const CustomTooltip = ({ payload, label }) => {
    if (payload && payload.length) {
      return (
        <Box sx={{ backgroundColor: 'white', border: '1px solid #ccc', padding: '8px', borderRadius: '4px' }}>
          <Typography variant="body2" gutterBottom>{label}</Typography>
          {payload.map((entry, index) => {
            const color = entry.stroke;
            const categoryName = entry.name;
            const count = entry.payload[`${categoryName}_count`];
            return (
              <Typography key={`tooltip-${index}`} variant="body2" sx={{ color }}>
                {categoryName}: {entry.value}
                <Typography component="span" variant="caption" sx={{ fontSize: '0.75rem' }}>
                  ({count} insights)
                </Typography>
              </Typography>
            );
          })}
        </Box>
      );
    }
    return null;
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
      <Box width="100%">
        <Typography variant="h6" align="left" gutterBottom>
          Average sentiment of insight categories over time
        </Typography>
        <Typography variant="body2" align="left" gutterBottom>
          Sentiment ranges from -2 (very negative) to 2 (very positive)
        </Typography>
        <br /><br />
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={sentimentData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis 
              domain={[-2, 2]} 
              ticks={[-2, -1, 0, 1, 2]}
              tickFormatter={(value) => value === -2 ? '-2 very negative' : (value === 2 ? '2 very positive' : value)}
              padding={{ top: 10, bottom: 10, left: 10 }} 
            />
            <Tooltip content={<CustomTooltip />} />
            {categories.map((category, index) =>
              visibleCategories[category] ? (
                <Line
                  key={category}
                  type="monotone"
                  dataKey={category}
                  stroke={`hsl(${(index * 72) % 360}, 70%, 50%)`}
                  dot={false}
                  connectNulls={true}
                />
              ) : null
            )}
          </LineChart>
        </ResponsiveContainer>
      </Box>

      <Box display="flex" flexWrap="wrap" justifyContent="center" gap={1} mt={2}>
        {categories.map((category, index) => (
          <FormControlLabel
            key={category}
            control={
              <Checkbox
                checked={visibleCategories[category]}
                onChange={() => handleCheckboxChange(category)}
                style={{ color: `hsl(${(index * 72) % 360}, 70%, 50%)` }}
                size="medium" 
              />
            }
            label={<Typography variant="body2" style={{ color: `hsl(${(index * 72) % 360}, 70%, 50%)` }}>{category}</Typography>}
          />
        ))}
      </Box>
    </Box>
  );
};

export default InsightsTrendsCharts;
